<template>
  <div>
    <validation-observer #default="{ invalid }">
      <b-row class="w-100 mt-2">
        <b-col cols="12">
          <b-row>

            <b-col class="col-12">
                <validation-provider rules="required" class="m-auto">
                  <b-form-group
                    :label="$t('campaigns.link_streaming_label')"
                    :description="''"    
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="`${content.url_info ? 'eye-link' : ''}`">
                        <b-link
                          class="text-white a-padding-eye-icon"
                          :href="content.url_info"
                          target="_blank"
                          v-if="content.url_info"
                        >
                          <feather-icon icon="EyeIcon"/>
                        </b-link>
                        <feather-icon icon="LinkIcon" v-else/>
                      </b-input-group-prepend>

                      <b-form-input
                        :placeholder="$t('campaigns.link_streaming_placeholder')"
                        v-model="content.url_info"
                        type="url"
                      />
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
            </b-col>

            <b-col class="col-12" v-if="show_date_publish">
              <validation-provider rules="required">
                <b-form-group
                  :label="$t('campaigns.date_publish')"
                  :description="''"        
                >
                  <b-form-datepicker
                    placeholder=""
                    v-model="content.published_date"
                    class="mb-2"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                  ></b-form-datepicker>
                </b-form-group>
              </validation-provider>
            </b-col>

            <b-col class="col-12">
              <form-engagements :external_form="invalid" :content="content" :net="net" :new_layout="new_layout" @onSubmit="onSubmit"></form-engagements>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import manage_invitation from "@/services/invitation";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import formEngagements from "@/views/components/campaign/formEngagements.vue";

import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BCol,
  BRow,
  BFormGroup,
  BLink,
  BFormDatepicker
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    BFormGroup,
    BLink,
    BFormDatepicker,
    formEngagements

  },
  data() {
    return {
      required,
    };
  },
  props: {
    uuids: {
      required: true,
      type: Object,
    },
    content: {
      required: true,
      type: Object,
    },
    net: {
      required: true,
      type: String,
    },
    info_add: {
      required: false,
      type: Object
    },
    new_layout: {
      type: Boolean
    },
    show_date_publish: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  methods: {
    changeValue(new_value, key) {
      this.content[key] = new_value
    },
    onSubmit(new_metrics) {
      this.content.number_of_impressions = new_metrics.impressions
      this.content.number_of_likes = new_metrics.likes
      this.content.number_of_comments = new_metrics.comments
      this.content.number_of_shares = new_metrics.shares
      this.content.number_of_times_saved = new_metrics.saved

      const form = new FormData();
      form.append("network", this.net);
      form.append("url_info", this.content.url_info);
      form.append("status", "content_published");
      form.append("content_type", "streaming");
      this.content.content_type = "streaming";

      form.append('campaign_influencer', undefined);
      form.append("number_of_impressions", this.content.number_of_impressions);
      form.append("number_of_likes", this.content.number_of_likes);
      form.append("number_of_comments", this.content.number_of_comments);
      form.append("number_of_shares", this.content.number_of_shares);

      if (new_metrics.saved) {
        form.append("number_of_times_saved", this.content.number_of_times_saved);
      }

      if (this.show_date_publish) {
        form.append('published_date', this.content.published_date);
      } else {
        form.append("published_date", (this.info_add.date_publish !== null) ? this.info_add.date_publish : "")
      }

      if (this.content.uuid === undefined) {

        form.append("campaign_influencer", this.uuids.campaign_influencer)
        form.append("network", this.net)
        form.append("content_type", "streaming");
        form.append("estimated_cost", (this.info_add.estimated_cost !== null) ? this.info_add.estimated_cost : "")

        manage_invitation.addContent(this.uuids.campaign_uuid, form).then((response) => {
          if (!response.uuid) {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.error'),
                  icon: 'BellIcon',
                  text: response.data.response.message.url_info[0],
                  variant: 'danger'
                }
              })
            })
          } else {
            this.$emit('closeModal', response)
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.contentAdd'),
                  icon: 'CheckCircleIcon',
                  text: this.$t('campaigns.contentSuccess'),
                  variant: 'success'
                }
              })
            })
          }
        })

      } else {
        manage_invitation
          .updateCampaignContent(this.uuids.campaign_uuid, this.content.uuid, form)
          .then((res) => {
            res.index = this.content.index;
            this.$emit('closeModal', res)
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.infoUpdated'),
                  icon: "CheckCircleIcon",
                  variant: "success",
                }
              })
            })
          })
          .catch(() => {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.error'),
                  icon: "BellIcon",
                  text: this.$t('campaigns.infoFailed'),
                  variant: "danger",
                }
              })
            })
          });
      }
    },
  },
};
</script>