<template>
  <b-row>
    <b-col class="col-12">
      <b-card
        :img-src="cover"
        img-alt="Image"
        img-top
        class="mb-2 card-header-campaign rounded"
        body-class="p-0 d-flex justify-content-center flex-wrap body-card-header"
      >
        <div class="edit-cover-header" id="edit-cover">
          <b-button
            variant="outline-light-gray"
            class="btn-icon rounded-circle outline-light-gray"
            @click="openFile('cover')"
          >
            <feather-icon icon="EditIcon" />
          </b-button>
        </div>
        <b-tooltip target="edit-cover" >{{$t('campaigns.editCover')}}</b-tooltip>
        <div class="container-avatar col-12">
          <b-col class="col-12 col-lg-5 order-2 order-lg-1 row mb-3 mb-lg-0" v-if="data_left">
            <div class="col mb-1" v-for="(data, index) in data_left" :key="index">
              <div v-if="data.traduction">
                <span class="d-block h5 text-muted text-center">{{$t(data.traduction)}}</span>
                <span class="d-block h3 font-weight-bold text-center">{{data.value}}</span>
              </div>

              <div class="col text-center mt-1" v-else @click="clickIcono(data)">
                <b-avatar square rounded="lg" variant="light-primary" class="cursor-pointer">
                  <feather-icon
                    size="18"
                    :icon="data.icon"
                  />
                </b-avatar>              
              </div>
            </div>
            
          </b-col>

          <div class="col-12 col-lg-2 order-1 order-lg-2 d-flex justify-content-center flex-wrap mb-lg-0">   
            <div class="position-relative">
              <b-avatar
                class="avatar-header"
                size="180"
                variant="light"
                :src="logo"
              />
              <div class="edit-avatar-header" id="edit-logo">
                <b-button
                  variant="outline-light-gray"
                  class="btn-icon rounded-circle outline-light-gray"
                  @click="openFile('logo')"
                >
                  <feather-icon icon="EditIcon" />
                </b-button>
                <b-form-file ref="file_upload" class="d-none" accept=".jpg, .png, .jpeg" @change="hasChanged"></b-form-file>
                <b-tooltip target="edit-logo">{{$t('campaigns.edit_logo')}}</b-tooltip>
              </div>
            </div>           

              
            <div class="h2 text-center mt-1 col-12">
              {{shortText(4, name)}}
            </div>

            <div v-if="badge !== undefined">
              <b-badge
                  class="profile-badge"
                  variant="light-warning"
                  v-if="badge.value"
              >
                  {{ $t(badge.traduction[0])}}
              </b-badge>
              <b-badge
                  class="profile-badge"
                  variant="light-success"
                  v-else
              >
                  {{ $t(badge.traduction[1])}}
              </b-badge>
            </div>

          </div>

          <b-col class="col-12 col-lg-5 order-3 row" v-if="data_right"> 
            <div class="col mb-1" v-for="(data, index) in data_right" :key="index">
              <span class="d-block h5 text-muted text-center">{{$t(data.traduction)}}</span>
              <div v-if="data.traduction === 'spentcard'">
                <price-managed :prefix="'$'" :price="data.value" :content_uuid="`${index}`" @changePriceChild="changeSpent"/>
              </div>
              <div v-else-if="data.traduction === 'campaigns.budget'">
                <price-managed :prefix="'$'" :price="data.value" :content_uuid="`${index}`" @changePriceChild="changeBudget"/>
              </div>
              <div v-else>
                <div class="d-flex justify-content-center align-items-center">
                  <span :id="`span-header-${index}`" class="d-block h3 font-weight-bold text-center">{{data.value}}</span>
                </div>
              </div>
            </div>
          </b-col>

          <div class="col-12 order-4 d-flex justify-content-center flex-wrap">
            <router-link :to="{name: 'brief', params: {uuid: campaign_uuid}}">
              <b-button class="button-brief mb-1" variant="outline-primary" pill>{{ $t('campaigns.seeBrief') }}</b-button>
            </router-link>
            <div v-if="!is_realty" class="d-flex justify-content-center flex-wrap">
              <b-button class="buttonAddInfluencer mb-1" variant="buttonAddInfluencer" pill @click="$emit('showInfluencer')">
                <feather-icon icon="UserPlusIcon"/>
                {{ $t('campaigns.addInfluencer') }}
              </b-button>
              <span id="button-csv">
                <b-button class="button-csv mb-1" pill :disabled="disabledUpdateCampaign || processing" @click="$emit('showModalImport')">
                  <b-img src="@/assets/images/elements/campaign/filecsv.svg" class="img-csv-campaign-header"/>
                  {{ $t("lists.addListCsv") }} 
                </b-button>
              </span>
              <b-tooltip target="button-csv" variant="danger" triggers="hover" placement="bottomright" v-if="processing">
                {{$t('campaigns.tooltipCsvProcess')}}
              </b-tooltip>
            </div>
          </div>
        </div>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BCard, BRow, BCol, BAvatar, BBadge, BButton, BImg, BTooltip, BFormFile
} from 'bootstrap-vue'
import campaign_service from '@/services/campaign';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import priceManaged from '../components/campaign/priceManaged.vue';
import { shortText } from '@/libs/utils/formats';

export default {
  components: {
    BCard,  
    BRow,
    BCol,
    BBadge,
    BAvatar,
    BButton,
    BImg,
    BTooltip,
    priceManaged,
    BFormFile,
  },
  data() {
    return {
      shortText,
      open_modal: false,
      budget: 0,
      spent: 0,
      placeholcer_budget: '',
      campaign_uuid: this.$route.params.uuid,
      upload_type: null,
    }
  },
  props: {
    cover: {
      type: String,
      required: true,
      default: ''
    },
    logo: {
      type: String,
      required: true,
      default: ''
    },
    name: {
      type: String,
      required: true,
    },
    data_left: {
      type: Array,
      required: false
    },
    data_right: {
      type: Array,
      required: false
    },
    data_middle: {
      type: Array,
      required: false
    },
    badge: {
      type: Object,
      required: false
    },
    processing: {
      type: Boolean,
      default: false
    },
    disabledUpdateCampaign: {
      type: Boolean,
      default: false
    },
    is_realty: {
      type: Boolean,
      required: false
    }
  },
  created() {
    this.data_right.forEach(data => {
      if (data.traduction === 'spentcard') {
        this.spent = parseInt(data.value.toString().replace('$', ''))
      } else if (data.traduction === 'campaigns.budget') {
        this.budget = parseInt(data.value.toString().replace('$', ''))
      }
    });
  },
  methods: {
    hasChanged() {
      setTimeout(() => {
        this.upload(this.$refs.file_upload.files[0]);
      },200)
    },
    openFile(upload_type) {
      this.upload_type = upload_type;
      this.$refs.file_upload.$refs['input'].click();
    },
    upload(file) {
      const formData = new FormData();
      this.upload_type === 'logo' ? formData.append('logo', file) : formData.append('cover', file);
      this.update(this.$route.params.uuid, formData);
    },
    update (campaign_uuid, formdata) {
      const self = this;
      campaign_service.updateCampaign(campaign_uuid, formdata).then(response => {
        if (response.uuid) {
          self.$swal({
            title: this.$t('campaigns.changeSuccess'),
            text: this.$t('campaigns.changeSuccess'),
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false,
            heightAuto: false
          });
          this.$emit('update_logo_cover', response);
        } else {
          const messages = Object.entries(response.data.response.message)
          let message = ''
          messages.forEach(error => {
            error[1].forEach(m => {
              message += m
            });
          })
          self.$swal({
            title:  this.$t('campaigns.Error'),
            text: message,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false,
            heightAuto: false
          });
        }
      });
    },
    changeSpent (new_spent) {
      if (this.spent !== new_spent) {
        this.spent = new_spent
        const form = new FormData();
        form.append('spent', new_spent);
        campaign_service.updateCampaign(this.$route.params.uuid, form).then(() => {
          this.budget = null;
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('campaigns.success_update_budget_title'),
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.$t('campaigns.success_update_spent_text')
              }
            })
          })
        }) 
      }
    },
    clickIcono(data) {
      this.$emit(data.callback)
    },
    changeBudget(new_budget) {
      if (this.budget !== new_budget) {
        this.budget = new_budget
        const form = new FormData();
        form.append('budget', new_budget);
        campaign_service.updateCampaign(this.$route.params.uuid, form).then(() => {
          this.$emit('updateBudget', new_budget);
          this.budget = null;
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              position: 'top-right',
              props: {
                title: this.$t('campaigns.success_update_budget_title'),
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.$t('campaigns.success_update_budget_text')
              }
            })
          })
        })
      }
    },
    changeValue(new_value) {
      this.budget = new_value;
    },
  }
}
</script>

<style>
.edit-avatar-header {
  position: absolute; 
  bottom: 0; 
  right: 0.5em; 
  z-index: 9; 
  background: white; 
  border-radius: 50%; 
  padding: 5px;
}
.edit-cover-header {
  position: absolute; 
  right: 0.5em; 
  z-index: 9; 
  background: white; 
  border-radius: 50%; 
  padding: 5px;
  top: 1.4em;
}
.card-header-none-data {
  padding: 0;
  height: 400px;
}
.card-header-campaign {
  padding: 0;
  height: 100%;
}
.card-header-campaign img {
  width: 100%;
  height: 250px;
  object-fit: cover;
}
.container-avatar {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: auto;
  position: relative;
  align-items: flex-end;
  bottom: 8.4em;
}
.avatar-header {
  min-width: 180px;
  padding: 0.7em;
}
.body-card-header {
  margin-bottom: -6.5em;
}
.avatar-influencer {
  padding: 0.7em;
}
.img-csv-campaign-header {
  width: 16px !important;
  height: 16px !important;
}
.outline-light-gray {
  color: rgb(165, 165, 165) !important;
  border: 1px solid rgb(165, 165, 165) !important;
}
.z-index-tooltip {
  z-index: 0 !important;
}
</style>