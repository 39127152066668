<template>
  <b-row>
    <b-col cols="12">
      <b-card  class="d-flex" no-body>
        <b-card-header>
          <div>
            <b-card-title class="analytics-title">{{$t('campaigns.contentDistribution') }}</b-card-title>
          </div>
        </b-card-header>

        <b-card-body>
          <component
            :is="apex_charts"
            type="scatter"
            height="350"
            :options="chartOptions"
            :series="series"
            :key="change_graph"
            @click="handleClick"
          >
          </component>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
} from "bootstrap-vue";
import { getDatev2 } from '@/libs/utils/times';

export default {
  name: "ScatterAnalytics",
  props: {
    influencers: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
  },
  data() {
    return {
      change_graph: false,
      series: [],
      chartOptions: {
        chart: {
          height: 350,
          type: 'scatter',
          zoom: {
            type: 'xy'
          }
        },
        dataLabels: {
          enabled: false
        },
        grid: {
          xaxis: {
            lines: {
              show: true
            }
          },
          yaxis: {
            lines: {
              show: true
            }
          },
        },
        xaxis: {
          type: 'datetime',
        },
        yaxis: {
          max: 70
        },
        tooltip: {
          custom: ({seriesIndex, dataPointIndex, w}) => {
            const date_instance = new Date(w.globals.initialSeries[seriesIndex].data[dataPointIndex][0])
            date_instance.setDate(date_instance.getDate() + 1);
            return (
              `<div class="width-tooltip-graph">
                  <div class="d-block bg-toottip-graph p-1">
                    ${getDatev2(new Date((date_instance)))}
                  </div>
                  <div class="p-1">
                    <strong class="mr-tooltip-graph"> ${w.globals.initialSeries[seriesIndex].name}
                    </strong>
                  </div>
                </div>`
            )
          }
        },
      },
      apex_charts: null,
    }
  },
  created() {
    window.addEventListener("resize", this.myEventHandler);
    this.formatContents()
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  destroyed() {
    window.removeEventListener("resize", this.myEventHandler);
  },
  methods: {
    handleClick(event, chartContext, config) {
      if (config.dataPointIndex !== -1) {
        this.clickPointer(config.globals.initialSeries[config.seriesIndex].data[config.dataPointIndex][2])        
      }
    },
    myEventHandler() {
      this.change_graph = !this.change_graph;
    },
    clickPointer(content) {
      const url = this.getUrl(content);
      window.open(url, "_blank");
    },
    getUrl(content) {
      if (content.url_info) return content.url_info
      else return content.image_content
    },
    formatContents() {
      const data = [
        {
          name: this.$t('influencerCategory.top-celebrity'),
          id: 'top-celebrity',
          symbolSize: 10,
          data: [],
          weight: 50,
        },
        {
          name: this.$t('influencerCategory.top-influencer-celebrity'),
          id: 'top-influencer-celebrity',
          symbolSize: 10,
          data: [],
          weight: 40,
        },
        {
          name: this.$t('influencerCategory.macroinfluencer'),
          id: 'macroinfluencer',
          symbolSize: 10,
          data: [],
          weight: 30,
        },
        {
          name: this.$t('influencerCategory.microinfluencer'),
          id: 'microinfluencer',
          symbolSize: 10,
          data: [],
          weight: 20,
        },
        {
          name: this.$t('influencerCategory.nanoinfluencer'),
          id: 'nanoinfluencer',
          symbolSize: 10,
          data: [],
          weight: 10, 
        },
      ];

      const contents = []

      this.influencers.forEach(influencer => {
        influencer.content.forEach(content => {
          if (content.published_date) contents.push({content, influencer: influencer.influencer})
        })
      })

      contents.sort((content1, content2) => {
        return new Date(content1.content.published_date).getTime() - new Date(content2.content.published_date).getTime()
      });

      contents.forEach((content, index) => {
        const has_url = this.checkUrlPublish(content.content)
        if (content.content.published_date && has_url) {
          const date_instance = new Date(content.content.published_date)
          date_instance.setDate(date_instance.getDate() + 1);
          const serie = data.find(item => item.id === content.influencer.calculate_category)
          const x = index * 5
          serie.data.push([date_instance.getTime(), x, content.content])
        }
      });
      this.series = data
      this.change_graph = !this.change_graph;
    },
    checkUrlPublish(content) {
      return content.url_info || content.image_content
    }
  },
  watch: {
    "$i18n.locale"() {
      this.formatContents();
    },
  },
};
</script>

<style>
.w-full {
  width: 100%;
}
</style>
