<template>
  <b-row class="match-height">
    <b-col class="col-12 col-lg-6 col-xl-6 col-xxl-6">
      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title class="analytics-title" id="avaible-instagram1">{{ $t('campaigns.mainCountries') }}</b-card-title>
          </div>
        </b-card-header>

        <!-- chart -->
        <b-card-body>
          <chartjs-component-horizontal-bar-chart
            :height="400"
            :data="data_countries"
            :options="options"
            class="cursor-pointer"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col class="col-12 col-lg-6 col-xl-6 col-xxl-6">
      <b-card no-body>
        <b-card-header>
          <div>
            <b-card-title class="analytics-title" id="avaible-instagram2">{{ $t('campaigns.mainCities') }}</b-card-title>
          </div>
        </b-card-header>

        <!-- chart -->
        <b-card-body>
          <chartjs-component-horizontal-bar-chart
            :height="400"
            :data="data_cities"
            :options="options"
            class="cursor-pointer"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col class="col-12 col-lg-4 col-xl-4 col-xxl-4">
      <b-card no-body>
        <b-card-header>
          <b-card-title class="analytics-title" id="avaible-instagram3">{{ $t('campaigns.impactedLanguages') }}</b-card-title>
        </b-card-header>

        <!-- chart -->
        <b-card-body>
          <chartjs-component-bar-chart
            :height="400"
            :data="data_languages"
            :options="options"
            class="cursor-pointer"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col class="col-12 col-lg-4 col-xl-4 col-xxl-4">
      <b-card no-body>
        <b-card-header>
          <b-card-title class="analytics-title" id="avaible-instagram4">{{ $t('campaigns.distributionEthnic') }}</b-card-title>
        </b-card-header>

        <!-- chart -->
        <b-card-body>
          <chartjs-component-bar-chart
            :height="400"
            :data="data_ethnicities"
            :options="options"
            class="cursor-pointer"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col class="col-12 col-lg-4 col-xl-4 col-xxl-4">
      <b-card no-body>
        <b-card-header>
          <b-card-title class="analytics-title">{{ $t('campaigns.sentimentChart') }}</b-card-title>
        </b-card-header>

        <b-card-body>
          <component
            :is="apex_charts"
            type="pie"
            height="325"
            class="mt-2 mb-1 cursor-pointer"
            :options="sentiment_graph.chartOptions"
            :series="sentiment_graph.series"
            :key="change_graph"
            
          />

          <div class="pt-25">
            <div
              v-for="(data,index) in legend_sentiment"
              :key="index"
              class="d-flex justify-content-between"
              :class="index === Object.keys(legend_sentiment).length - 1 ? '':'mb-1'"
            >
              <div class="series-info">
                <feather-icon
                  :icon="data.icon"
                  size="16"
                  class="mr-50"
                  :class="data.color"
                />
                <span @dblclick="clickGraph()" class="font-weight-bolder">{{ data.text }}</span>
              </div>
              <span @dblclick="clickGraph()">{{ data.prc }}</span>
            </div>
          </div>
        </b-card-body>
      </b-card>
    </b-col>
    <form-sentiment @updatedSentiments="updatedSentiments" :show_form="show_form" :key="change_form" :sentiments="legend_sentiment"/>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import { decimalFixed, capitalize } from '@/libs/utils/formats';
import { getCountry , getLanguage } from '@/libs/utils/others';
import { getColorSentiment } from '@/libs/utils/series_graphs';

export default {
  name: 'DemographyAnalytics',
  props: {
    countries: {
      type: Array,
      default: () => []
    },
    cities: {
      type: Array,
      default: () => []
    },
    languages: {
      type: Array,
      default: () => []
    },
    ethnicities: {
      type: Array,
      default: () => []
    },
    sentiments: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    ChartjsComponentBarChart: () => import('../charts-components/ChartjsComponentBarChart.vue'),
    ChartjsComponentHorizontalBarChart: () => import('../charts-components/ChartjsComponentHorizontalBarChart.vue'),
    formSentiment: () => import('./formSentiment.vue')
  },
  data() {
    return {
      change_graph: false,
      data_countries: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#28dac6',
            borderColor: 'transparent',
          },
        ],
      },
      data_cities: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#28dac6',
            borderColor: 'transparent',
          },
        ],
      },
      data_languages: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#28dac6',
            borderColor: 'transparent',
          },
        ],
      },
      options: {
        elements: {
          rectangle: {
            borderWidth: 2,
            borderSkipped: 'bottom',
          },
        },
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          display: false,
        },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const value = data.datasets[0].data[tooltipItem.index]
              const output = `${value} %`
              return output
            },
          },
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scales: {
          xAxes: [
            {
              display: true,
              gridLines: {
                display: true,
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
              scaleLabel: {
                display: false,
              },
              ticks: {
                fontColor: '#6e6b7b',
              },
            },
          ],
          yAxes: [
            {
              display: true,
              gridLines: {
                color: 'rgba(200, 200, 200, 0.2)',
                zeroLineColor: 'rgba(200, 200, 200, 0.2)',
              },
            },
          ],
        },
      },
      data_ethnicities: {
        labels: [],
        datasets: [
          {
            data: [],
            backgroundColor: '#28dac6',
            borderColor: 'transparent',
          },
        ]
      },
      sentiment_graph: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: {
              show: false,
            },
          },
          labels: [],
          dataLabels: {
            enabled: false,
          },
          yaxis: {
            labels: {
              formatter(val) {
                return `${val}%`
              },
            },
          },
          legend: { show: false },
          stroke: {
            width: 4,
          },
          colors: [$themeColors.warning, $themeColors.danger, $themeColors.primary],
        },
      },
      legend_sentiment: [],
      show_form: false,
      change_form: false,
      apex_charts: null,
    }
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  created() {
    this.formatCountries()
    this.formatCities()
    this.formatLanguages()
    this.formatEthnicities()
    this.formatSentiments()
  },
  methods: {
    clickGraph() {
      this.show_form = true
      this.change_form = !this.change_form
    },
    formatCountries() {
      const labels = []
      const series = []
      this.countries.forEach(country => {
        const co = getCountry(country.code)
        const name = co
        const prc = decimalFixed(country.prc,2);
        labels.push(name)
        series.push(prc)
      })
      this.data_countries.labels = labels
      this.data_countries.datasets[0].data = series
    },
    formatCities() {
      const labels = []
      const series = []
      this.cities.forEach(city => {
        const prc = decimalFixed(city.prc,2);
        labels.push(city.name)
        series.push(prc)
      })
      this.data_cities.labels = labels
      this.data_cities.datasets[0].data = series
    },
    formatLanguages() {
      const labels = []
      const series = []
      this.languages.forEach(language => {
        const lan = getLanguage(language.code)
        const name = lan;
        const prc = decimalFixed(language.prc,2);
        labels.push(name)
        series.push(prc)
      })
      this.data_languages.labels = labels
      this.data_languages.datasets[0].data = series
    },
    formatEthnicities() {
      const labels = []
      const series = []
      this.ethnicities.forEach(ethnicity => {
        const prc = decimalFixed(ethnicity.prc,2);
        const name = capitalize(ethnicity.name)
        labels.push(name)
        series.push(prc)
      })
      this.data_ethnicities.labels = labels
      this.data_ethnicities.datasets[0].data = series
    },
    formatSentiments() {
      const keys = Object.keys(this.sentiments)
      const labels = []
      const series = []
      this.legend_sentiment = []
      keys.forEach(sentiment => {
        const color = getColorSentiment(sentiment)
        const obj = {
          icon: 'CircleIcon',
          color,
          prc: `${this.sentiments[sentiment]} %`,
          text: this.$t(`stats.${sentiment}`),
          real_text: sentiment,
          real_prc: this.sentiments[sentiment]
        }
        labels.push(this.$t(`stats.${sentiment}`))
        series.push(this.sentiments[sentiment])
        this.legend_sentiment.push(obj)
      })
      this.sentiment_graph.series = series
      this.sentiment_graph.chartOptions.labels = labels
    },
    updatedSentiments(sentiments) {
      this.legend_sentiment = []
      const keys = Object.keys(sentiments)
      const labels = []
      const series = []
      keys.forEach(sentiment => {
        const color = getColorSentiment(sentiment)
        const obj = {
          icon: 'CircleIcon',
          color,
          prc: `${sentiments[sentiment]} %`,
          text: this.$t(`stats.${sentiment}`),
          real_text: sentiment,
          real_prc: sentiments[sentiment]
        }
        labels.push(this.$t(`stats.${sentiment}`))
        series.push(sentiments[sentiment])
        this.legend_sentiment.push(obj)
      })
      this.sentiment_graph.series = series
      this.sentiment_graph.chartOptions.labels = labels
    }
  },
  watch: {
    '$i18n.locale'() {
      this.change_graph = !this.change_graph;
      this.formatSentiments()
    },
  }  
}
</script>

