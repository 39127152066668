<template>
  <div class="text-center mt-5"  v-if="loading_creators">
    <b-spinner class="w-5rem" type="grow" variant="primary"></b-spinner>
    <div class="h2 text-center mt-1 col-12">
      {{$t('campaigns.loadingCreator')}}
    </div>
  </div>
  <div v-else class="h-450 show-scroll" ref="scrollable" @scroll="checkScroll()">
    <b-card class="mt-7">
      <b-row class="match-height">
        <b-col md="12" class="mb-2 flex">
          <!-- TODO: move component filters -->
          <b-row>
            <b-col lg="9" class="d-flex align-items-center">
              <div class="demo-inline-spacing">
                <b-form-radio
                  v-model="radio_selected"
                  :name="`radio-filters-${status_influencers}`"
                  class="mt-0 mb-05"
                  value="all"
                  @change="categoryFilterAll()"
                >
                  Todos
                </b-form-radio>
                <b-form-radio
                  v-for="(filter, index) in radio_filters"
                  :key="index"
                  :name="`radio-filters-${status_influencers}`"
                  class="mt-0 mb-05"
                  :value="filter"
                  v-model="radio_selected"
                  @change="categoryFilter(filter)"
                >
                  {{ $t(`influencerCategory.${filter}`)}}
                </b-form-radio>
              </div>
            </b-col>
            <b-col lg="3">
              <div class="text-right">
                <b-dropdown
                  id="dropdown-right"
                  :text="$t('campaigns.actions')"
                  variant="flat-secondary"
                  :disabled="disabledUpdateCampaign()"
                  right
                >
                  <b-dropdown-item @click="selectAll()" :disabled="disabledUpdateCampaign()">
                    <feather-icon
                      icon="CheckSquareIcon"
                      size="16"
                      class="align-middle"
                    />
                    {{ $t('campaigns.actionSelectAll') }}
                  </b-dropdown-item>
                  <b-dropdown-item @click="checkedNames = []" :disabled="disabledUpdateCampaign()">
                    <feather-icon
                      icon="XSquareIcon"
                      size="16"
                      class="align-middle"
                    />
                    {{ $t('campaigns.actionRemoveSelection') }}
                  </b-dropdown-item>
                  <b-dropdown-divider />
                  <b-dropdown-item @click="exportProposals()">
                    <feather-icon icon="FileTextIcon" size="16" class="align-middle"/>
                    {{$t('campaigns.export_proporsals')}}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="approvedUsers()"
                    :disabled="disabledUpdateCampaign()"
                  >
                    <feather-icon
                      icon="UserCheckIcon"
                      size="16"
                      class="align-middle"
                    />
                    {{ $t('campaigns.approveBtn') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="rejectedUsers()"
                    :disabled="disabledUpdateCampaign()"
                  >
                    <feather-icon
                      icon="UserXIcon"
                      size="16"
                      class="align-middle"
                    />
                    {{ $t('campaigns.rejectBtn') }}
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="removedUsers()"
                    :disabled="disabledUpdateCampaign()"
                  >
                    <feather-icon
                      icon="UserMinusIcon"
                      size="16"
                      class="align-middle"
                    />
                    {{ $t('campaigns.deleteBtn') }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <div class="w-100" :key="change_influencers">
          <b-row class="w-100" v-if="influencers.length > 0" :key="change_influencers">
            <b-col v-for="(influencer, index) in influencers" :key="index" class=" col-12 col-sm-6 col-lg-4 col-xl-3">
              <!-- TODO: Remove prop style -->
              <b-card
                class="text-center card-all-users-profiles"
                style="box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1) !important"
                footer-class="without-border"
                :ref="`card-influencer-${influencer.uuid}`"
              >
                <div class="mb-1 position-relative">
                  <b-form-checkbox
                    v-model="checkedNames"
                    :value="influencer.uuid"
                    class="custom-control-primary"
                    :disabled="disabledUpdateCampaign()"
                  >
                  </b-form-checkbox>
                  <feather-icon
                    @click="deletedCreator(influencer.uuid, influencer.influencer.username, index, influencer.status)"
                    icon="XIcon"
                    size="16"
                    class="align-middle text-secondary position-absolute cursor-pointer delete-creator"
                    :disabled="disabledUpdateCampaign()"
                  />
                </div>
                <div class="profile-image-wrapper mb-2">
                  <div class="profile-image p-0 position-relative">
                      <b-avatar class="socialnet-campaigns p-0" variant="light">
                        <b-img  :src="`${getIconWhite(influencer.influencer.network.toLowerCase())}`" :class="getIconColor(influencer.influencer.network.toLowerCase())" fluid></b-img>
                      </b-avatar>
                    <b-avatar
                      size="200"
                      variant="light-secondary"
                      :src="influencer.influencer.image_url"
                    >
                      <img class="img-200" :src="getImageInfluencer(influencer.influencer)" @error="replaceInsta($event, influencer.influencer.username, influencer.influencer.network)">
                    </b-avatar>
                  </div>
                </div>
                <router-link :to="'/perfil/' + influencer.influencer.network.toLowerCase() + '/' + influencer.influencer.username">
                  <h3>
                    {{ getUsername(influencer.influencer) }}
                    <image-verified v-if="influencer.influencer.is_verified" class="square-20"/>
                  </h3>
                </router-link>
                <h5 class="text-muted font-weight-bolder">
                  {{ $t(`influencerCategory.${influencer.influencer.calculate_category}`)}}
                </h5>

                <div :class="getClasses(influencer.influencer)">
                  <div v-if="influencer.influencer.followers > 0" class="mb-1 col-12 col-sm-6">
                    <h6 class="text-muted font-weight-bolder">
                      {{ $t('campaigns.followers') }}
                    </h6>
                    <h3 class="mb-0">
                      {{ engagement(influencer.influencer.followers) }}
                    </h3>
                  </div>
                  <div v-if="influencer.influencer.engagement_rate > 0" class="mb-1 col-12 col-sm-6">
                    <h6 class="text-muted font-weight-bolder">
                      {{ $t('campaigns.erTitle') }}
                    </h6>
                    <h3 class="mb-0">
                      {{ decimalFixed(influencer.influencer.engagement_rate, 2) }} %
                    </h3>
                  </div>
                </div>
                <template #footer>
                  <hr class="mb-2">
                  <StatusInfluencer @childStatus="getFromChild" :influencerStatus="influencer.status" :influencerUuid="influencer.uuid"></StatusInfluencer>
                </template>
              </b-card>
            </b-col>
          </b-row>
          <div v-else class="d-flex mt-3 align-items-center justify-content-center flex-column">
            <img v-if="status_influencers === 'all'" src="@/assets/images/elements/campaign/all.png" class="w-50"/>
            <img v-if="status_influencers === 'pending'" src="@/assets/images/elements/campaign/pending.png" class="w-50"/>
            <img v-if="status_influencers === 'approved'" src="@/assets/images/elements/campaign/approved.png" class="w-50"/>
            <img v-if="status_influencers === 'rejected'" src="@/assets/images/elements/campaign/rejected.png" class="w-50"/>
            <b-button variant="outline-primary" pill @click="$emit('openSidebar')" :disabled="disabledUpdateCampaign()">{{ $t('campaigns.addInfluencer') }}</b-button>
          </div>
        </div>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BRow, BCol, BCard, BAvatar, BFormCheckbox, BFormRadio, BDropdown, BDropdownItem, BDropdownDivider, BSpinner, BButton, BImg,
} from 'bootstrap-vue'
import StatusInfluencer from '@/views/campaings/statusInfluencer'
import service from "@/services/campaign";
import config_env from '@/services/config';
import imgProfile from "@/assets/images/elements/error.png";
import ImageVerified from '@/views/components/image/ImageVerified.vue';
import { getIconWhite, getIconColor } from '@/libs/utils/icons';
import { getImageInfluencer, getMotivationalPhrase } from '@/libs/utils/others';
import { decimalFixed } from '@/libs/utils/formats';
import { isUrl, getAssetUrl, checkResourceExists } from '@/libs/utils/urls'
import { hasPermission } from '@/libs/utils/user';

export default {
  components: {
    StatusInfluencer,
    ImageVerified,
    BRow,
    BCol,
    BCard,
    BAvatar,
    BFormCheckbox,
    BFormRadio,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BSpinner,
    BButton,
    BImg,
  },
  props: {
    campaign_name: {
      type: String,
      default: ''
    },
    status_influencers: {
      type: String,
      default: 'all'
    }
  },
  data () {
    return {
      getMotivationalPhrase,
      hasPermission,
      checkResourceExists,
      getAssetUrl,
      isUrl,
      decimalFixed,
      getImageInfluencer,
      getIconWhite,
      getIconColor,
      radio_selected: 'all',
      radio_filters: [
        'top-celebrity',
        'top-influencer-celebrity',
        'microinfluencer',
        'macroinfluencer',
        'nanoinfluencer',
      ],
      influencers_without_filter: [],
      influencers: [],
      checkedNames: [],
      change_filter: false,
      has_next_influencers: false,
      current_page: 1,
      loading_creators: false,
      change_influencers: 0,
      filter_category: '',
      interval_loading: null
    }
  },
  created() {
    this.loading_creators = true
    this.getInfluencers()
  },
  methods: {
    getInfluencers() {
      service.getCampaignInfluencers(this.$route.params.uuid, this.current_page, this.status_influencers, this.filter_category)
        .then(response => {
          this.loading_creators = false
          if (response.results) {
            this.influencers_without_filter = this.influencers_without_filter.concat(response.results)
            this.influencers = this.influencers.concat(response.results)
            this.has_next_influencers = response.next !== null
          } else {
            this.has_next_influencers = false
          }
        })
    },
    checkScroll() {
      const scroll = this.$refs.scrollable.offsetHeight + this.$refs.scrollable.scrollTop
      if (scroll >= this.$refs.scrollable.scrollHeight && this.has_next_influencers) {
        this.current_page += 1
        this.getInfluencers()
      }
    },
    getUsername(influencer) {
      if (influencer.network === 'youtube') return influencer.name
      return influencer.username
    },
    replaceInsta(event, username, network) {
      if (network.toLowerCase() === 'instagram') {
        this.$http.get(`${config_env.urlCore}/api/v1/finder/profile/image/${username}`)
          .then(function(response) {
            const img_url = isUrl(response.data.response.image) ? response.data.response.image : getAssetUrl(response.data.response.image);
            checkResourceExists(img_url).then(function(result) {
              if (!result) {
                event.target.src = imgProfile;
              } else {
                event.target.src = img_url;
              }
            });

          })
          .catch(() => {
            event.target.src = imgProfile;
          });
      } else event.target.src = imgProfile;
    },
    getFromChild () {
      this.$emit('childStatus')
    },
    engagement (num) {
      let result = ''
      if (num < 1000) {
        result = num
      }
      if (num > 999) {
        result = `${(Math.round(num / 100) / 10)} K`
      }
      if (num > 999999) {
        result = `${(Math.round(num / 100000) / 10)} M`
      }
      if (num > 999999999) {
        result = `${(Math.round(num / 100000000) / 10)} B`
      }
      return result
    },
    categoryFilterAll () {
      this.influencers_without_filter = []
      this.filter_category = ''
      this.current_page = 1
      this.loading_creators = true
      this.getInfluencers()
      this.influencers = this.influencers_without_filter
      this.change_filter = !this.change_filter
    },
    categoryFilter (category) {
      this.loading_creators = true
      this.influencers_without_filter = []
      this.current_page = 1
      this.filter_category = `&category=${category}`
      this.getInfluencers()
      this.change_filter = !this.change_filter
      this.influencers = []
      this.influencers_without_filter.forEach(influencer => {
        if (influencer.influencer.calculate_category === category) {
          this.influencers.push(influencer)
        }
      })
    },
    selectAll () {
      if (this.disabledUpdateCampaign()) return;

      this.checkedNames = []
      this.influencers.forEach(i => {
        this.checkedNames.push(i.uuid)
      })
    },
    statusApproved () {
      this.$swal({
        title: this.$t('campaigns.approveInfluencer'),
        text: this.$t('campaigns.influencerSuccess'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    approvedUsers () {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: this.$t('campaigns.approveCreator'),
        text: this.$t('campaigns.approveSuccess'),
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.realty.accept'),
        cancelButtonText: this.$t('campaigns.realty.cancel'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false

      }).then(result => {
        if (result.value) {
          this.statusUsers('approved')
        }
      })
    },
    rejectedUsers () {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: this.$t('campaigns.textConfirmRejectCreators'),
        text: this.$t('campaigns.textRejectCreators'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmRejectCreators'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          this.statusUsers('rejected')
        }
      })
    },
    removedUsers () {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: this.$t('campaigns.textConfirmCreators'),
        text: this.$t('campaigns.textCreators'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirmCreators'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          this.statusUsers('removed')
        }
      })
    },
    statusUsers (status) {
      service.influencerAction(this.$route.params.uuid, this.checkedNames, status)
        .then(() => {
          this.change_influencers += 1

          this.checkedNames.forEach(influencer_uuid => {
            const influencer = this.influencers_without_filter.find(influ => influ.uuid === influencer_uuid)
            if (influencer) {
              if (status !== 'removed') {
                influencer.status = status
              } else {
                this.checkedNames.forEach(influencer_uuid => {
                  const index_of = this.influencers.findIndex(i => i.uuid === influencer_uuid)
                  this.influencers.splice(index_of, 1);
                  this.influencers_without_filter.splice(index_of, 1);
                })
              }
            }
          })
          if (status === 'approved') {
            this.approvedInflu()
          }
          if (status === 'rejected') {
            this.rejectedInflu()
          }
          if (status === 'removed') {
            this.removedInflu()
            this.$emit('bulkRemove', this.status_influencers)
          } else {
            this.$emit('childStatus', status)
          }
        })
        .catch(() => {
          this.errorMessage()
        })
    },
    approvedInflu () {
      this.$swal({
        title: this.$t('campaigns.approveInfluencers'),
        text: this.$t('campaigns.influencersSuccess'), 
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    rejectedInflu () {
      this.$swal({
        title: this.$t('campaigns.rejectInfluencers'),
        text: this.$t('campaigns.textReject'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    removedInflu () {
      this.$swal({
        title: this.$t('campaigns.deleteInfluencers'),
        text: this.$t('campaigns.textDelete'),
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-success'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    deletedCreator (uuid, name, index, status_influencer) {
      if (this.disabledUpdateCampaign()) return;
      this.$swal({
        title: `${this.$t('campaigns.textConfirm')} ${name} ?`,
        text: this.$t('campaigns.textRemove'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('campaigns.msjConfirm'),
        cancelButtonText: this.$t('campaigns.msjCancel'),
        customClass: {
          confirmButton: 'btn confirm-button',
          cancelButton: 'btn btn-outline-secondary ml-1'
        },
        buttonsStyling: false,
        heightAuto: false
      }).then(result => {
        if (result.value) {
          service.influencerAction(this.$route.params.uuid, [uuid], 'removed')
            .then(() => {
              this.removedInflu()
              this.$emit('updateCounter')
              this.influencers.splice(index, 1);
              this.$emit('deleteInfluencer', this.status_influencers, status_influencer)
            })
            .catch(() => {
              this.errorMessage()
            })
        }
      })
    },
    errorMessage () {
      this.$swal({
        title: this.$t('campaigns.errormessage'),
        text: this.$t('campaigns.tryAgainLater'),
        icon: 'warning',
        customClass: {
          confirmButton: 'btn btn-danger'
        },
        buttonsStyling: false,
        heightAuto: false
      })
    },
    disabledUpdateCampaign() {
      return !hasPermission('change_campaign')
    },
    exportProposals() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: getMotivationalPhrase(),
          });
        }
      }, 5000);
      service.exportProposals(this.$route.params.uuid, this.checkedNames)
        .then(response => {
          const downloadLink = document.createElement("a");
          const blob = new Blob(["\ufeff", response]);
          const url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `Influencers ${this.campaign_name}.csv`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);

          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
        .catch(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        })
    },
    getClasses(influencer) {
      if (influencer.engagement_rate > 0) return 'row mt-3 container-er-followers'
      return 'd-flex justify-content-center align-items-center mt-3'
    }
  }
}
</script>
<style scoped>
.mb-05 {
  margin-bottom: 0.5em;
}
</style>
<style>
  .without-border {
    border: none !important;
  }

  .socialnet-campaigns {
    font-size: 1.3em;
    position: absolute;
    left: 60%;
    bottom: 0;
    background: white;
    z-index: 9;
    padding: 2%;
    border-radius: 50%;
  }
  @media(min-width:2000px) {
    .socialnet-campaigns {
      left: 55%;
    }
  }
  @media(max-width: 992px) {
    .socialnet-campaigns {
      left: 54%;
    }
  }
  .card-all-users-profiles {
    box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.1) !important;

    height: 95%;
  }
  .img-200 {
    width: 200px
  }
  .h-450 {
    height: 1280px;
    overflow: scroll;
  }
  .show-scroll::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px !important;
    height: 7px;
  }
  .delete-creator {
    right: 0;
    top: 0;
    z-index: 9
  }
  .square-20 {
    width: 20px;
    height: 20px;
  }
  .w-5rem {
    width: 5rem;
    height: 5rem;
  }
</style>
