<template>
  <b-row class="d-flex align-items-center">
    <b-col class="col-12 col-lg-3 col-xl-3 col-xxl-3">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0 mx-auto analytics-title">
             {{ $t('campaigns.influencer') }}
          </h4>
        </b-card-header>

        <component
          :is="apex_charts"
          type="radialBar"
          height="245"
          :options="graph.chartOptions"
          :series="graph.series"
        />
        <b-row class="text-center mx-0">
          <b-col
            cols="6"
            class="border-top border-right d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-0">
               {{ $t('campaigns.contentTotal') }}
            </b-card-text>
            <h3 class="font-weight-bolder mb-50">
              {{production}}
            </h3>
          </b-col>

          <b-col
            cols="6"
            class="border-top d-flex align-items-between flex-column py-1"
          >
            <b-card-text class="text-muted mb-0">
               {{ $t('campaigns.contentPublished') }}
            </b-card-text>
            <h3 class="font-weight-bolder mb-0">
              {{published}}
            </h3>
          </b-col>
        </b-row>
      </b-card>
    </b-col>

    <b-col class="col-12  col-lg-9 col-xl-9 col-xxl-9">
      <b-row>
        <div class="alert alert-warning col-12 message-api" role="alert">
          <div>
            <feather-icon icon="InfoIcon" class="mr-1" size="20"/>
              {{ $t("campaigns.msjAPI") }}
          </div> 
        </div>
      </b-row>

      <b-row v-if="!printing">
        <b-col cols="12" class="text-right">
          <b-button @click="print()" variant="blue-button" class="float-right mb-1 blue-button">
            {{$t('print')}}
            <b-icon
              font-scale="1"
              icon="printer"
              id="btnPrint"
              >
            </b-icon>
          </b-button>
        </b-col>
      </b-row>

      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0 analytics-title">
             {{ $t('campaigns.importantStats') }}
          </h4>
          <b-card-text class="text-muted mb-0">
             {{ $t('campaigns.updated') }}
          </b-card-text>
        </b-card-header>

        <b-row>
          <b-col>
            <statistic-card-horizontal
              icon="UsersIcon"
              color="warning"
              :statistic="numberFormat(total_scope)"
              :statistic-title="$t('scope_number_total')"
            />
          </b-col>

          <b-col>
            <statistic-card-horizontal
              icon="TrendingUpIcon"
              :statistic="numberFormat(impressions)"
              :statistic-title="$t('campaigns.printsTotal')"
            />
          </b-col>

          <b-col>
            <feather-icon icon="HelpCircleIcon" class="text-muted float-right mt-2 mr-2 cursor-pointer" size="20" id="tooltip-engagement-total"/>
            <b-tooltip target="tooltip-engagement-total">{{$t('campaigns.engagement_total_tooltip')}}</b-tooltip>
            <statistic-card-horizontal
              icon="UserIcon"
              color="info"
              :statistic="numberFormat(engagements_number)"
              :statistic-title="$t('campaigns.engagementsTotal')"
            />
          </b-col>

          <b-col>
            <statistic-card-horizontal
              icon="BoxIcon"
              color="danger"
              :statistic="`${numberFormat(decimalFixed(er, 2))} %`"
              statistic-title="ER %"
            />
          </b-col>

          <b-col>
            <b-card no-body>
              <b-card-body class="d-flex justify-content-left align-items-center">
                <b-avatar
                  variant="light-success"
                  size="45"
                >
                  <feather-icon
                    size="21"
                    icon="MousePointerIcon"
                  />
                </b-avatar>
                <div class="truncate p-1">
                  <h2 class="mb-25 font-weight-bolder">
                    <price-managed prefix="" :price="mutable_clicks" :content_uuid="'clicks'" @changePriceChild="changeClicks" :classes="'h2 mb-25 font-weight-bolder'"/>
                  </h2>
                  <span>{{ $t('campaigns.totalClicks') }}</span>
                </div>
              </b-card-body>
            </b-card>
          </b-col>
        </b-row>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardText, BIcon, BButton, BCardBody, BAvatar, BTooltip
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import campaign_service from '@/services/campaign';
import { print } from '@/libs/utils/others';
import { decimalFixed, numberFormat } from '@/libs/utils/formats';
const toast_notification = () => import('@core/components/toastification/ToastificationContent.vue')

export default {
  name: 'ResumeAnalytics',
  props: {
    production: {
      type: Number,
      default: 0
    },
    published: {
      type: Number,
      default: 0
    },
    engagements: {
      type: Object,
      default: () => {}
    },
    followers: {
      type: Number,
      default: 0
    },
    clicks: {
      type: Number,
      default: 0
    },
    campaingName: {
      type: String,
      default: ''
    },
    analytics_overwrite: {
      type: Object,
    }
  },
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardText,
    BIcon,
    BButton,
    BCardBody,
    BAvatar,
    BTooltip,
    StatisticCardHorizontal: () => import('@core/components/statistics-cards/StatisticCardHorizontal.vue'),
    priceManaged: () => import('../../priceManaged.vue'),
  },
  data() {
    return {
      decimalFixed,
      numberFormat,

      printing: false,
      percentage_published: 0,
      impressions: 0,
      engagements_number: 0,
      er: 0,
      total_scope: 0,
      mutable_clicks: this.clicks,
      graph: {
        series: [0],
        chartOptions: {
          chart: {
            sparkline: {
              enabled: true,
            },
            dropShadow: {
              enabled: true,
              blur: 3,
              left: 1,
              top: 1,
              opacity: 0.1,
            },
          },
          colors: ['#51e5a8'],
          plotOptions: {
            radialBar: {
              offsetY: -10,
              startAngle: -150,
              endAngle: 150,
              hollow: {
                size: '77%',
              },
              track: {
                background: '#ebe9f1',
                strokeWidth: '50%',
              },
              dataLabels: {
                name: {
                  show: false,
                },
                value: {
                  color: '#5e5873',
                  fontSize: '2.86rem',
                  fontWeight: '600',
                },
              },
            },
          },
          fill: {
            type: 'gradient',
            gradient: {
              shade: 'dark',
              type: 'horizontal',
              shadeIntensity: 0.5,
              gradientToColors: [$themeColors.success],
              inverseColors: true,
              opacityFrom: 1,
              opacityTo: 1,
              stops: [0, 100],
            },
          },
          stroke: {
            lineCap: 'round',
          },
          grid: {
            padding: {
              bottom: 30,
            },
          },
        },
      },
      apex_charts: null,
    }
  },
  created() {
    if (this.published >= this.production) {
      this.percentage_published = 100
    } else if (this.published === 0) {
      this.percentage_published = 0
    } else {
      this.percentage_published = (this.published * 100) / this.production
    }

    this.graph.series = [numberFormat(decimalFixed(this.percentage_published, 2))]

    this.formatData();
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  methods: {
    changeClicks(new_clicks) {
      if (this.mutable_clicks !== new_clicks) {
        this.mutable_clicks = new_clicks
        const form = new FormData();
        form.append('clicks', new_clicks);
        campaign_service.updateCampaign(this.$route.params.uuid, form).then(() => {
          toast_notification().then((toast) => {
            this.$toast({
              component: toast.default,
              position: 'top-right',
              props: {
                title: this.$t('campaigns.success_update_budget_title'),
                icon: 'CoffeeIcon',
                variant: 'success',
                text: this.$t('campaigns.success_update_clicks_text')
              }
            })
          })
        })
      }
    },
    print() {
      this.printing = true
      window.scrollTo({ top: 0, behavior: 'smooth' });
      const self = this
      setTimeout(function() {
        print('p', `Analytics de ${self.campaingName}`, document.body, function () {
          self.printing = false
        })
      }, 500);
    },
    formatData() {
      const keys = Object.keys(this.engagements)
      keys.forEach(item => {
        if (item === 'number_of_impressions') {
          this.impressions = this.engagements[item]
        }

        if (item === 'engagements') {
          this.engagements_number = this.engagements[item]
        }

      })
      if (this.followers > 0 && (this.impressions +  this.engagements_number) > 0) {
        this.er = Object.keys(this.analytics_overwrite).length > 0 && this.analytics_overwrite.er ? this.analytics_overwrite.er : (this.engagements_number / this.followers) * 100
      }

      this.total_scope = decimalFixed((this.impressions * 75.17) / 100)
    }
  }
}
</script>
<style>
 .message-api{
    padding: 10px;
    text-align: center;
}
</style>

