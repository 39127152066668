<template>
  <b-row class="justify-content-md-center">
    <b-col
      v-for="story in stories"
      :key="story.id"
      class="col-12 col-sm-6 col-md-6 col-lg-2 ml-1 mr-1"
    >
      <div class="d-flex justify-content-center">
        <b-img-lazy class="max-width-100" @click="openStory(story.url)" :src="story.preview"></b-img-lazy>
      </div>
      <div class="d-flex justify-content-center mt-2 mb-2">
        <b-button variant="success" @click="openStory(story.url)">{{$t('mediaKits.download')}}</b-button>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BImgLazy,
  BButton
} from "bootstrap-vue";

export default {
  name: 'InstagramStories',
  components: {
    BRow,
    BCol,
    BImgLazy,
    BButton
  },
  props: {
    stories: {
      type: Array,
      default: () => []
    },
  },
  data() {
    return {
    }
  },
  created() {
  },
  methods: {
    openStory(url) {
      window.open(url, 'blank')
    }
  }
}
</script>

<style>
  .max-width-100 {
    max-width: 100%;
  }
</style>

