<template>
  <div>
    <b-card
      class="text-center m-0"
      footer-class="without-border"
    >
      <div class="profile-image-wrapper mb-2">
        <div class="profile-image p-0 position-relative">
          <div>
            <i :class="`${utils.getClassNetworkSpan(event.extendedProps.network)} socialnet`"/>
          </div>
          <b-avatar
            size="114"
            variant="light-secondary"
            :src="event.extendedProps.profile_url"
          >
          </b-avatar>
        </div>
      </div>

      <h3>
        {{ event.title }}
        <image-verified v-if="event.extendedProps.is_verified" class="square-20"/>
      </h3>

      <h5 class="text-muted font-weight-bolder">{{$t(`influencerCategory.${event.extendedProps.type_influencer}`)}}</h5>
      <div v-if="event.extendedProps.followers > 0" class="d-flex justify-content-center align-items-center mt-3">
        <div>
          <h6 class="text-muted font-weight-bolder">
            {{ $t('campaigns.followers') }}
          </h6>
          <h3 class="mb-0">
              {{ utils.getFormat(event.extendedProps.followers) }}
          </h3>
        </div>
      </div>

      <div class="mt-2 ml-1 mr-1">
        <b-row class="d-flex justify-content-between align-items-center">
          <b-col cols="6">
            <div>
              <h6 class="text-muted font-weight-bolder">{{$t('campaigns.datePublished')}}:</h6>
              <h3 class="mb-0">{{getDatePublished()}}</h3>
            </div>
          </b-col>

          <b-col cols="6">
            <div>
              <span v-if="event.extendedProps.link_content">
                <b-button @click="redirectPublish()" variant="success">{{$t('campaigns.viewPublish')}}</b-button>
              </span>
              <span v-else>
                <h6 class="text-muted font-weight-bolder">{{$t('campaigns.noPublished')}}</h6>
              </span>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BAvatar, BRow, BButton, BCol
} from 'bootstrap-vue'

import utils from '@/libs/utils'
import ImageVerified from '@/views/components/image/ImageVerified.vue';

export default {
  name: 'DetailEvent',
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  components: {
    ImageVerified,
    BCard,
    BAvatar,
    BRow,
    BButton,
    BCol
  },
  data() {
    return {
      utils
    }
  },
  created() {
  },
  methods: {
    getDatePublished() {
      const date = new Date(this.event.start)
      return utils.getDatev2(date)
    },
    redirectPublish() {
      const url = this.event.extendedProps.link_content

      window.open(url, '_blank')
    }
  },
  watch: {
    '$i18n.locale'() {
    }
  },
}
</script>

<style>
  .position-relative {
    position: relative;
  }
  .square-20 {
    width: 20px;
    height: 20px;
  }
</style>

