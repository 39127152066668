<template>
  <div class="d-flex justify-content-center">
    <component
      :is="apex_charts"
      type="radialBar"
      height="30"
      width="30"
      :options="options"
      :series="series"
      :id="identifier"
    />
    <b-tooltip :target="identifier" triggers="hover" :variant="variant_tooltip">
      {{published}}/{{contents.length}}
    </b-tooltip>
  </div>
</template>

<script>
import { BTooltip } from 'bootstrap-vue';
import { $themeColors } from '@themeConfig'

export default {
  name: 'DonutGraphContents',
  components: {
    BTooltip
  },
  directives: {
  },
  props: {
    contents: {
      type: Array,
      required: true
    },
    identifier: {
      type: String,
      required: true
    },
    type_influencer: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      series: [],
      options: {
        grid: {
          show: false,
          padding: {
            left: -15,
            right: -15,
            top: -12,
            bottom: -15,
          },
        },
        colors: [$themeColors.primary],
        plotOptions: {
          radialBar: {
            hollow: {
              size: '22%',
            },
            track: {
              background: '#e9ecef',
            },
            dataLabels: {
              showOn: 'always',
              name: {
                show: false,
              },
              value: {
                show: false,
              },
            },
          },
        },
        stroke: {
          lineCap: 'round',
        },
      },
      published: 0,
      colors: [
        {type: 'top-influencer-celebrity', color: 'danger'},
        {type: 'macroinfluencer', color: 'primary'},
        {type: 'microinfluencer', color: 'warning'},
        {type: 'nanoinfluencer', color: 'success'},
        {type: 'top-celebrity', color: 'info'},
      ],
      variant_tooltip: 'primary',
      apex_charts: null,
    };
  },
  created() {
    const color = this.colors.find(type => type.type === this.type_influencer)

    if (color) {
      this.options.colors = [$themeColors[color.color]]
      this.variant_tooltip = color.color
    }
    this.getContents()
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  methods: {
    getContents() {
      if (this.contents.length < 1) {
        this.series = [0]
      } else {
        this.contents.forEach(item => {
          if (item.url_info !== null) {
            this.published += 1
          } else if (item.image_content !== null) this.published += 1
        });
        const percentage = (this.published * 100) / this.contents.length

        this.series = [percentage]
      }
    },
  },
};
</script>

<style>
</style>