<template>
  <div>
    <span 
      :id="`span-header-${content_uuid}`"
      v-if="!editing"
      @dblclick="enableEdit()"
      :class="classes"
    >
      {{prefix}}{{separatebycomma(own_price)}}{{postfix}}
    </span>
    <b-form-input
      v-show="editing"
      v-model="own_price"
      class="form-control"
      placeholder="10,000"
      @blur="saveCost"
      @input.native="hasChanged"
      @keyup.enter="saveCost"
      :disabled="disabledUpdateCampaign()"
      ref="edit_price"
    />
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue'
import { separatebycomma, trimNumber } from '@/libs/utils/formats'
import { hasPermission } from '@/libs/utils/user'

export default {
  props: {
    price: {
      required: true
    },
    content_uuid: {
      required: true
    },
    prefix: {
      required: false,
      default: () => ''
    },
    postfix: {
      required: false,
      default: () => ''
    },
    classes: {
      type: String,
      default: 'd-block h3 font-weight-bold text-center'
    }
  },
  components: {
    BFormInput
  },
  data () {
    return {
      hasPermission,
      trimNumber,
      separatebycomma,
      own_price: this.price,
      editing: false,
    }
  },
  created () {
  },
  methods: {
    enableEdit() {
      if (this.disabledUpdateCampaign()) return;
      this.editing = true;
      const self = this;
      const prev = self.own_price;
      self.own_price = '';
      setTimeout(function() {
        self.$refs.edit_price.$el.focus();
        self.own_price = prev;
      }, 10);
    },
    hasChanged(data) {
      data.target.value = separatebycomma(this.own_price.split(',').join(''))
    },
    saveCost() {
      const new_price = trimNumber(`${this.own_price}`);
      this.editing = false;
      this.$emit('changePriceChild', new_price, this.content_uuid)
    },
    disabledUpdateCampaign() {
      return !hasPermission('change_campaign')
    }
  }
}
</script>
