<template>
  <section id="dashboard-analytics" v-if="get_response">
    <!-- Resumen de analytics -->
    <resume-analytics 
      :published="published" 
      :production="production" 
      :engagements="engagements" 
      :followers="followers" 
      :campaingName="campaingName" 
      :clicks="clicks"
      :analytics_overwrite="analytics_overwrite"
    />

    <!-- Section donuts -->
    <donuts-analytics :type_influencers="type_influencers" :networks="networks"/>

    <b-row v-if="get_ages">
      <b-col cols="12">
        <genders :ages_gender="ages_gender" />
      </b-col>
    </b-row>

    <!-- Section demography -->
    <demography-analytics v-if="get_demography" :countries="countries" :cities="cities" :languages="languages" :ethnicities="ethnicities" :sentiments="sentiments"/>

    <!-- Scatter -->
    <scatter-analytics :influencers="scatter_published" v-if="has_data_scatter"/>

    <!-- Summary -->
    <summary-counter :counters="counters"/>
  </section>
</template>

<script>
import {BRow, BCol} from 'bootstrap-vue'
import service from '@/services/campaign'
import { getMotivationalPhrase } from '@/libs/utils/others'
import ResumeAnalytics from '../components/campaign/analytics/components/ResumeAnalytics.vue'
import DonutsAnalytics from '../components/campaign/analytics/components/DonutsAnalytics.vue'
import genders from '@/views/components/campaign/analytics/genders'
import DemographyAnalytics from '../components/campaign/analytics/components/DemographyAnalytics.vue'
import ScatterAnalytics from '../components/campaign/analytics/components/ScatterAnalytics.vue'
import SummaryCounter from '../components/campaign/analytics/components/SummaryCounter.vue'

export default {
  name: 'AnalyticsCampaign',
  components: {
    BRow,
    BCol,
    ResumeAnalytics,
    DonutsAnalytics,
    genders,
    DemographyAnalytics,
    ScatterAnalytics,
    SummaryCounter
  },
  props: {
    campaingName: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      getMotivationalPhrase,
      get_response: false,
      published: 0,
      production: 0,
      engagements: [],
      type_influencers: {},
      networks: [],
      ages_gender: [],
      countries: [],
      cities: [],
      languages: [],
      ethnicities: [],
      sentiments: {},
      scatter_published: [],
      counters: [],
      followers: 0,
      printing: false,
      clicks: 0,
      get_demography: false,
      get_ages: false,
      has_data_scatter: false,
      interval_loading: null,
      analytics_overwrite: {}
    }
  },
  created() {
    this.$vs.loading({
      type: 'sound',
      background: 'rgba(255, 255, 255, 1)',
      text: getMotivationalPhrase(),
    });

    this.interval_loading = setInterval(() => {
      const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

      if (prev_loading) {
        prev_loading.classList.add('fade-out');
        setTimeout(function() {
          prev_loading.remove()
        }, 500);

        this.$vs.loading({
          type: 'sound',
          background: 'rgba(255, 255, 255, 1)',
          text: getMotivationalPhrase(),
        });
      }
    }, 5000);

    service.getNewStats(this.$route.params.uuid)
      .then(response => {
        this.followers = response.counter.followers
        this.clicks = response.counter.clicks
        this.production = response.counter.proposals
        this.published = response.counter.published
        this.engagements = response.engagements
        this.type_influencers = response.type_influencers
        this.networks = response.networks
        this.sentiments = response.sentiments
        this.counters = response.engagements
        this.get_response = true
        this.analytics_overwrite = !response.analytics_overwrite ? {} : response.analytics_overwrite;
        setTimeout(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        }, 500);
      })

    service.getNewStatsDemography(this.$route.params.uuid)
      .then(response => {
        this.countries = response.demography.countries
        this.cities = response.demography.cities
        this.languages = response.demography.languages
        this.ethnicities = response.demography.ethnicities
        this.get_demography = true;
      })

    service.getNewStatsAges(this.$route.params.uuid)
      .then(response => {
        this.ages_gender = response.content_age.demography_by_age;
        this.get_ages = true;
      })

    service.getCampaignManage(this.$route.params.uuid).then((response) => {
      this.has_data_scatter = true
      this.scatter_published = response.results;
    })
  },
  methods: {
  },
}
</script>
