<template>
  <b-row class="match-height">
    <b-col class="col-12 col-lg-4 col-xl-4 col-xxl-4">
      <b-card no-body>
        <b-card-header>
          <b-card-title class="analytics-title">{{ $t('campaigns.influencersTotal') }} {{total_influencers}}</b-card-title>
        </b-card-header>

        <b-card-body>
          <chartjs-component-polar-area-chart
            :height="350"
            :data="data_type"
            :options="options_type"
            :key="change_graph"
            class="cursor-pointer"
          />
        </b-card-body>
      </b-card>
    </b-col>

    <b-col class="col-12 col-lg-4 col-xl-4 col-xxl-4">
      <b-card no-body>
        <b-card-header>
          <b-card-title class="analytics-title">{{ $t('campaigns.contentNetwork') }} {{total_contents}}</b-card-title>
        </b-card-header>

        <b-card-body>
          <component
          :is="apex_charts"
          class="cursor-pointer"
          height="275"
          :key="change_graph"
          :options="options_network_apex"
          :series="series_network"
          v-if="change_network"
          >
          </component>

          <div
            v-for="(network,index) in legend_networks_show"
            :key="index"
            :class="index < legend_networks_show.length-1 ? 'mb-1':''"
            class="d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <b-avatar size="20px" variant="light">
                <b-img :src="getIconWhite(network.network.toLowerCase())" :class="getIconColor(network.network.toLowerCase(), false)" fluid/>
              </b-avatar>
              <span class="font-weight-bold ml-75 mr-25">{{ network.network }}</span>
              <span>- {{ network.percentage }}%</span>
            </div>
          </div>

          <div class="d-flex align-items-center mt-1 mb-1" v-if="legend_networks.length > 3">
            <feather-icon
              icon="ChevronsDownIcon"
              size="24"
              v-b-toggle="'collapse-2'"
              class="icon-chevrn mt-1 cursor-pointer w-100"
              v-if="!more_networks"
            />
            <feather-icon
              v-else
              icon="ChevronsUpIcon"
              size="24"
              v-b-toggle="'collapse-2'"
              class="icon-chevrn mt-1 cursor-pointer w-100"
            />
          </div>
          <b-collapse id="collapse-2" v-model="more_networks" v-if="legend_networks.length > 3">
            <div
              v-for="(network,index) in legend_networks_expand"
              :key="index"
              :class="index < legend_networks_expand.length-1 ? 'mb-1':''"
              class="d-flex justify-content-between"
            >
              <div class="d-flex align-items-center">
                <b-avatar size="20px" variant="light">
                  <b-img :src="getIconWhite(network.network.toLowerCase())" :class="getIconColor(network.network.toLowerCase(), false)" fluid/>
                </b-avatar>
                <span class="font-weight-bold ml-75 mr-25">{{ network.network }}</span>
                <span>- {{ network.percentage }}%</span>
              </div>
            </div>
          </b-collapse>
        </b-card-body>
      </b-card>
    </b-col>

    <b-col class="col-12 col-lg-4 col-xl-4 col-xxl-4">
      <b-card no-body>
        <b-card-header>
          <b-card-title class="analytics-title">{{ $t('campaigns.contentFormat') }} {{total_contents}}</b-card-title>
        </b-card-header>

        <b-card-body>
          <component
            :is="apex_charts"
            class="cursor-pointer"
            height="275"
            :key="change_graph"
            :options="options_contents"
            :series="series_content"
            v-if="change_network"
          >
          </component>

          <div
            v-for="(network, index) in legend_content_type_show"
            :key="index"
            :class="index < legend_content_type_show.length-1 ? 'mb-1':''"
            class="d-flex justify-content-between"
          >
            <div class="d-flex align-items-center">
              <b-icon class="content-type" :icon="`${network.icon}`" :style="`color: ${options_contents.colors[index]}`"/>
              <span class="font-weight-bold ml-75 mr-25">{{ (network.type === 'Reel') ? 'Reel / TikTok / Short' : network.type }}</span>
              <span>- {{ network.percentage }}%</span>
            </div>
          </div>

          <div class="d-flex align-items-center mt-1 mb-1" v-if="legend_content_type.length > 3">
            <feather-icon
              icon="ChevronsDownIcon"
              size="24"
              v-b-toggle="'collapse-3'"
              class="icon-chevrn mt-1 cursor-pointer w-100"
              v-if="!more_types"
            />
            <feather-icon
              v-else
              icon="ChevronsUpIcon"
              size="24"
              v-b-toggle="'collapse-3'"
              class="icon-chevrn mt-1 cursor-pointer w-100"
            />
          </div>
          <b-collapse id="collapse-3" v-model="more_types" v-if="legend_content_type.length > 3">
            <div
              v-for="(network,index) in legend_content_type_expand"
              :key="index"
              :class="index < legend_content_type_expand.length-1 ? 'mb-1':''"
              class="d-flex justify-content-between"
            >
              <div class="d-flex align-items-center">
                <b-icon class="content-type" :icon="`${network.icon}`" :style="`color: ${options_contents.colors[index+3]}`"/>
                <span class="font-weight-bold ml-75 mr-25">{{ network.type }}</span>
                <span>- {{ network.percentage }}%</span>
              </div>
            </div>
          </b-collapse>
        </b-card-body>
      </b-card>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow, BCol, BCard, BCardHeader, BCardTitle, BCardBody, BIcon, VBToggle, BCollapse, BAvatar, BImg,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'
import { decimalFixed } from '@/libs/utils/formats';
import { getContent, getDropdownNetworks, getClassNetworkSpan, getColor, getIconWhite, getIconColor } from '@/libs/utils/icons';

export default {
  name: 'DonutsAnalytics',
  props: {
    type_influencers: {
      type: Object,
      default: () => {}
    },
    networks: {
      type: Array,
      default: () => []
    }
  },
  directives: {
    'b-toggle': VBToggle
  },
  components: {
    BAvatar,
    BImg,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BIcon,
    BCollapse,
    ChartjsComponentPolarAreaChart: () => import('@/views/charts-and-maps/charts/chartjs/charts-components/ChartjsComponentPolarAreaChart.vue'),
  },
  data() {
    return {
      getIconWhite,
      getIconColor,
      change_graph: false,
      more_networks: false,
      total_influencers: 0,
      total_contents: 0,
      options_type: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        legend: {
          position: 'right',
          labels: {
            usePointStyle: true,
            padding: 25,
            boxWidth: 10,
            fontColor: '#6e6b7b',
          },
        },
        tooltips: {
          // Updated default tooltip UI
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
        scale: {
          scaleShowLine: true,
          scaleLineWidth: 1,
          ticks: {
            display: false,
            fontColor: '#6e6b7b',
          },
          reverse: false,
          gridLines: {
            display: false,
          },
        },
        animation: {
          animateRotate: false,
        },
      },
      data_type: {
        labels: ['Africa', 'Asia', 'Europe', 'America', 'Antarctica', 'Australia'],
        datasets: [
          {
            backgroundColor: [
              '#836AF9',
              '#ffe802',
              $themeColors.primary,
              '#299AFF',
              '#4F5D70',
              '#28dac6',
            ],
            data: [19, 17.5, 15, 13.5, 11, 9],
            borderWidth: 0,
          },
        ],
      },
      options_network: {
        responsive: true,
        maintainAspectRatio: false,
        responsiveAnimationDuration: 500,
        cutoutPercentage: 60,
        legend: { display: false },
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const label = data.datasets[0].labels[tooltipItem.index] || ''
              const value = data.datasets[0].data[tooltipItem.index]
              const output = ` ${label} : ${value} %`
              return output
            },
          },
          shadowOffsetX: 1,
          shadowOffsetY: 1,
          shadowBlur: 8,
          shadowColor: 'rgba(0, 0, 0, 0.25)',
          backgroundColor: $themeColors.light,
          titleFontColor: $themeColors.dark,
          bodyFontColor: $themeColors.dark,
        },
      },
      series_content: [],
      options_contents: {
        labels: [],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        colors: [
          '#836AF9',
          '#28dac6',
          '#ffe802',
          '#FDAC34',
          '#299AFF',
          '#4F5D70',
          '#2c9aff',
          '#84D0FF',
          '#EDF1F4',
          'rgba(0, 0, 0, 0.25)',
          '#666ee8',
          '#ff4961',
          '#6e6b7b',
          'rgba(200, 200, 200, 0.2)',
        ],
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: this.$t('campaigns.totalContents'),
                  formatter: () => { return `${this.total_contents}` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
      series_network: [],
      change_network: false,
      options_network_apex: {
        colors: [],
        labels: [],
        chart: {
          type: 'donut',
          toolbar: {
            show: false,
          },
        },
        yaxis: {
          labels: {
            formatter(val) {
              return `${val}%`
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        legend: { show: false },
        comparedResult: [2, -3, 8],
        stroke: { width: 0 },
        plotOptions: {
          pie: {
            startAngle: 0,
            donut: {
              labels: {
                show: true,
                name: {
                  offsetY: 15,
                },
                value: {
                  offsetY: -15,
                  formatter(val) {
                    // eslint-disable-next-line radix
                    return `${val}%`
                  },
                },
                total: {
                  show: true,
                  offsetY: 15,
                  label: this.$t('campaigns.totalContents'),
                  formatter: () => { return `${this.total_contents}` },
                },
              },
            },
          },
        },
        responsive: [
          {
            breakpoint: 1325,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 1200,
            options: {
              chart: {
                height: 120,
              },
            },
          },
          {
            breakpoint: 1045,
            options: {
              chart: {
                height: 100,
              },
            },
          },
          {
            breakpoint: 992,
            options: {
              chart: {
                height: 120,
              },
            },
          },
        ],
        grid: {
          padding: {
            right: -20,
            bottom: -8,
            left: -20,
          },
        },
      },
      legend_networks: [],
      legend_content_type: [],
      legend_networks_show: [],
      legend_networks_expand: [],
      legend_content_type_show: [],
      legend_content_type_expand: [],
      more_types: false,
      apex_charts: null,
    }
  },
  created() {
    this.networks.forEach(network_summary => {
      if (network_summary.counter > 0) {
        this.total_contents += network_summary.counter
      }
    })

    this.formatTypeInfluencers();
    this.formatContentNetworks();
    this.formatContentType()
  },
  async mounted() {
    this.apex_charts = (await import('vue-apexcharts')).default;
  },
  methods: {
    getNetworkColor(net) {
      const network_colors = {
        twitter: '#40AAEC',
        instagram: '#CD4D4D',
        facebook: '#1A66F3',
        youtube: '#CF302B',
        tiktok: '#EC3F79',
        blog: '#F0A23F',
        linkedin: '#346297',
        spotify: '#64D862',
        pinterest: '#E73631',
        twitch: '#924DF7',
        snapchat: '#F5D90D'
      }
      return network_colors[net]
    },
    
    formatTypeInfluencers() {
      const keys = Object.keys(this.type_influencers)
      const series = [];
      const labels = [];

      keys.forEach(key => {
        const value = this.type_influencers[key]
        this.total_influencers += value
        if (value > 0) {
          series.push(value)
          labels.push(this.$t(`influencerCategory.${key}`))
        }
      })
      this.data_type.labels = labels
      this.data_type.datasets[0].data = series
    },
    formatContentNetworks() {
      const option_networks = getDropdownNetworks()
      const colors = []
      const labels = []
      const series = []
      this.networks.forEach(network_summary => {
        if (network_summary.counter > 0) {
          const network_name = option_networks.find(net => net.value === network_summary.network)
          const percentage = decimalFixed((network_summary.counter * 100) / this.total_contents,2);
          labels.push(network_name.network)
          series.push(parseFloat(percentage))
          colors.push(this.getNetworkColor(network_summary.network))
          const obj = {
            network: network_name.network,
            icon: getClassNetworkSpan(network_summary.network),
            color: getColor(network_summary.network),
            counter: network_summary.counter,
            percentage
          }

          this.legend_networks.push(obj)
        }
      })
      this.legend_networks_show = this.legend_networks.slice(0, 3);
      this.legend_networks_expand = this.legend_networks.slice(3, this.legend_networks.length);
      this.series_network = series
      this.options_network_apex.colors = colors
      this.options_network_apex.labels = labels
      const self = this
      setTimeout(() => {
        self.change_network = true
      }, 50)
    },
    formatContentType() {
      const labels = []
      const series = []
      const data = []
      this.legend_content_type = []

      this.networks.forEach(network_summary => {
        network_summary.contents.forEach(content => {
          const exist_summary = data.find(item => item.content_type === content.content_type)
          if (!exist_summary) {
            data.push({content_type: content.content_type, counter: content.counter})
          } else {
            exist_summary.counter += content.counter
          }
        })
      })

      data.forEach(item => {
        if (item.counter > 0) {
          const percentage = decimalFixed((item.counter * 100) / this.total_contents,2);
          const obj = {
            type: this.$t(`invitation.${item.content_type}`),
            icon: getContent(item.content_type),
            counter: item.counter,
            percentage
          }
          this.legend_content_type.push(obj)
          labels.push(this.$t(`invitation.${item.content_type}`))
          series.push(parseFloat(percentage))
        }
      })

      this.series_content = series
      this.options_contents.labels = labels
      this.legend_content_type_show = this.legend_content_type.slice(0, 3);
      this.legend_content_type_expand = this.legend_content_type.slice(3, this.legend_content_type.length);
    }
  },
  watch: {
    '$i18n.locale'() {
      this.change_graph = !this.change_graph;
      this.options_contents.plotOptions.pie.donut.labels.total.label = this.$t('campaigns.totalContents')
      this.options_network_apex.plotOptions.pie.donut.labels.total.label = this.$t('campaigns.totalContents')
      this.formatTypeInfluencers()
      this.formatContentType()
    },
  }
}
</script>
