<template>
  <b-modal 
    v-model="show" 
    :title="$t('campaigns.addContentModal')" 
    size="md" 
    title-class="text-center-modal" 
    centered hide-footer
  >
    <b-row class="border-bottom">
      <b-col class="col-12 mb-1">
        <b-dropdown
            no-flip
            :text="$t(select_text_default)"
            :class="`w-md-75 w-100 dropdownNet ${select_text_default !== 'default_select_net' ? 'p-auto' : ''}`"
            v-model="network"
            variant="outline"
            menu-class="submenu"
        >
          <template #button-content>
            <b-avatar variant="ligth" size="20" class="mr-1">
            <b-img 
              :src="getDropdownNetworks().find((item) => item.value === select_text_default.toLowerCase()).icon" 
              :class="getIconColor(select_text_default.toLowerCase(), false)"
              v-if="select_text_default !== 'default_select_net'" fluid/>
            </b-avatar>
            <span class="text-default-networks-modal">{{$t(select_text_default)}}</span>
          </template>
            <b-dropdown-item @click="dropdownItemClick(item)" v-for="item in getDropdownNetworks()" :key="item.value">
              <b-avatar variant="ligth" size="20">
                <b-img :src="item.icon" :class="getIconColor(item.network.toLowerCase(), false)" fluid/>
              </b-avatar>
              <span> {{ item.network }}</span>
            </b-dropdown-item>
        </b-dropdown>
      </b-col>

      <b-col class="col-12  mb-1">
        <v-select v-model="content_type" :options="options_types" value-field="value" text-field="label">
        </v-select>
      </b-col>

      <b-col class="col-12 mb-1">
        <b-form-datepicker
            v-model="date_publish"
            :placeholder="$t('campaigns.date_publish')"
            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
            locale="es"
        />
      </b-col>

      <b-col class="col-12 col-lg-6 mb-1" v-if="false">
        <b-input-group class="m-auto">
          <b-input-group-prepend is-text>
            <feather-icon icon="DollarSignIcon" />
          </b-input-group-prepend>
          <input-number-comma :price="estimated_cost" identifier="number_of_interactions" @changeValue="changeValue" :placeholder="'Ingresa el costo estimado'"></input-number-comma>
        </b-input-group>
      </b-col>

      <b-col class="col-12 mb-1 d-flex justify-content-end">
        <div class="col-10 d-flex align-items-center">
          <span class="col-6 text-right">{{content_type.label}}</span>
          <b-form-spinbutton v-model="amount_contents" min="1" max="100" class="col-6"></b-form-spinbutton>
        </div>
      </b-col>

    </b-row>

    <b-row v-if="!show_form_metrics" class="d-flex justify-content-center my-2">
      <b-button
        variant="success success-button-invitations"
        class="success-button-invitations"
        @click="onSubmit()"
        :disabled="checkForm()"
      >
        {{$t('dashboard.save')}}
      </b-button>
    </b-row>

    <story
      v-if="show_form_metrics && (content_type.value === 'story' || content_type.value === 'snap')"
      :content="content_to_add"
      :uuids="{campaign_uuid, campaign_influencer}"
      :net="network"
      :info_add="{date_publish, estimated_cost}"
      @closeModal="result_form"
    />
    <video-form
      v-if="show_form_metrics && content_type.value === 'video'"
      :uuids="{campaign_uuid, campaign_influencer}"
      :content="content_to_add"
      :net="network"
      :info_add="{date_publish, estimated_cost}"
      @closeModal="result_form"
    />
    <photo-form
      v-if="show_form_metrics && content_type.value === 'photo'"
      :uuids="{campaign_uuid, campaign_influencer}"
      :content="content_to_add"
      :net="network"
      :info_add="{date_publish, estimated_cost}"
      @closeModal="result_form"
    />
    <streams-form
      v-if="show_form_metrics && content_type.value === 'streaming'"
      :uuids="{campaign_uuid, campaign_influencer}"
      :content="content_to_add"
      :net="network"
      :info_add="{date_publish, estimated_cost}"
      @closeModal="result_form"
    />
    <TVInstagram
      v-if="show_form_metrics && (content_type.value === 'instagram_tv' || content_type.value === 'reel')"
      :uuids="{campaign_uuid, campaign_influencer}"
      :content="content_to_add"
      :reel="false"
      :net="network"
      :info_add="{date_publish, estimated_cost}"
     @closeModal="result_form"
    />
    <Post
      v-if="show_form_metrics && content_type.value === 'post'"
      :uuids="{campaign_uuid, campaign_influencer}"
      :content="content_to_add"
      :net="network"
      :info_add="{date_publish, estimated_cost}"          
      @closeModal="result_form"
    />
  </b-modal>
</template>

<script>
import {
  BModal, BRow, BCol, BImg, BDropdown, BDropdownItem, BFormDatepicker, BInputGroup, BInputGroupPrepend, BButton, BFormSpinbutton, BAvatar
} from "bootstrap-vue";
import vSelect from 'vue-select';
import story from "@/views/pages/invitation/newInvitation/forms/story.vue";
import videoForm from "@/views/pages/invitation/newInvitation/forms/video.vue";
import photoForm from "@/views/pages/invitation/newInvitation/forms/photoForm.vue";
import streamsForm from "@/views/pages/invitation/newInvitation/forms/streams.vue";
import TVInstagram from "@/views/pages/invitation/newInvitation/forms/instagramTV.vue";
import Post from "@/views/pages/invitation/newInvitation/forms/post.vue";
import inputNumberComma from "@/views/components/inputs/inputNumberComma.vue";
import manage_invitation from "@/services/invitation";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import service_campaign from "@/services/campaign";
import { getDropdownNetworks, getIconColor } from '@/libs/utils/icons';

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BImg,
    BDropdown,
    BDropdownItem,
    vSelect,
    BFormDatepicker,
    BInputGroup,
    BInputGroupPrepend,
    story,
    videoForm,
    photoForm,
    streamsForm,
    TVInstagram,
    Post,
    inputNumberComma,
    BButton,
    BFormSpinbutton,
    BAvatar
  },
  data() {
    return {
      getIconColor,
      getDropdownNetworks,
      show: false,
      network: null,
      select_text_default: 'default_select_net',
      content_type: this.$t('select_net'),
      invalid_form: true,
      type_contents : [
        {
          value: "instagram",
          types: [
            { value: "story", label: "Story" },
            { value: "photo", label: this.$t('invitation.photo') },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "youtube",
          types: [
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
            { value: "reel", label: "Short" },
          ],
        },
        {
          value: "tiktok",
          types: [
            { value: "reel", label: "TikTok" },
            { value: "story", label: "Story" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "facebook",
          types: [
            { value: "post", label: "Post" },
            { value: "photo", label: this.$t('invitation.photo') },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "twitter",
          types: [
            { value: "post", label: "Post" },
            { value: "photo", label: this.$t('invitation.photo') },
            { value: "reel", label: "Reel" },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "linkedin",
          types: [
            { value: "post", label: "Post" },
            { value: "photo", label: this.$t('invitation.photo') },
            { value: "video", label: "Video" },
            { value: "streaming", label: "Live stream" },
          ],
        },
        {
          value: "spotify",
          types: [{ value: "podcast", label: "Podcast" }],
        },
        {
          value: "twitch",
          types: [
            { value: "streaming", label: "Live stream" },
            { value: "clip", label: "Clip" }
          ],
        },
        {
          value: "pinterest",
          types: [
            { value: "photo", label: this.$t('invitation.photo') },
            { value: "video", label: "Video" },
          ],
        },
        {
          value: "blog",
          types: [{ value: "post", label: "Blog Post" }],
        },
        {
          value: "snapchat",
          types: [
            { value: "story", label: "Story" },
            { value: "reel", label: "Spotlight" },
          ],
        },
      ],
      options_types: [],
      date_publish: null,
      estimated_cost: 0,
      campaign_uuid: this.$route.params.uuid,
      content_to_add : {
        image_content: null,
        number_of_views: null,
        url_info: null,
        number_of_prints: null,
        number_of_responses: null,
        number_of_clicks_to_the_link: null,
        number_of_likes: null,
        number_of_shares: null,
        scope_number: null,
        number_of_interactions: null,
        number_of_times_saved: null,
        number_of_comments: null,
      },
      amount_contents: 1,
    }
  },
  props: {
    show_add_content: {
      type: Boolean,
      default: false
    },
    campaign_influencer: {
      type: String,
      required: true
    },
    show_form_metrics: {
      type: Boolean,
      default: true
    },
    content_published: {
      type: Boolean,
      default: false
    }
  },
  created() {
    const networks = getDropdownNetworks()
    this.show = this.show_add_content
    if (localStorage.getItem('add_content_network') && localStorage.getItem('add_content_content_type') && localStorage.getItem('add_content_date_publish') && this.validJson(localStorage.getItem('add_content_content_type'))) {
      this.network = localStorage.getItem('add_content_network')
      this.select_text_default = networks.find(item => localStorage.getItem('add_content_network') === item.value).network

      const type = this.type_contents.find(item => item.value === this.network)
      this.options_types = type.types
      this.content_type = JSON.parse(localStorage.getItem('add_content_content_type'))

      this.date_publish = localStorage.getItem('add_content_date_publish')
    }
  },
  methods: {
    validJson(string) {
      try {
        JSON.parse(string);
        return true;
      } catch (error) {
        return false;
      }
    },
    checkForm() {
      if (this.network && this.content_type.value && this.date_publish) return false
      return true
    },
    changeValue(new_value) {
      this.estimated_cost = new_value
    },
    dropdownItemClick(net) {
      this.select_text_default = net.network
      this.network = net.value
      const type = this.type_contents.find(item => item.value === this.network)
      this.options_types = type.types
      this.content_type = type.types[0]
    },
    result_form(data) {
      this.$emit('contentAdded', data)
      this.show = false
    },
    onSubmit() {
      localStorage.setItem('add_content_network', this.network)
      localStorage.setItem('add_content_date_publish', this.date_publish)
      localStorage.setItem('add_content_content_type', JSON.stringify(this.content_type))
      const form = new FormData();
      form.append("status", "content_pending");
      form.append("campaign_influencer", this.campaign_influencer)

      form.append("network", this.network);
      form.append("content_type", this.content_type.value);
      form.append('published_date', this.date_publish);
      form.append("estimated_cost", this.estimated_cost);
      form.append("number_repeat", this.amount_contents);

      manage_invitation.addContent(this.campaign_uuid, form).then((response) => {
        if (!response.uuid) {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              props: {
                title: this.$t('campaigns.error'),
                icon: 'BellIcon',
                text: response.data.response.message.url_info[0],
                variant: 'danger'
              }
            })
          })
        } else if (this.content_published) {
          service_campaign.influencerAction(this.$route.params.uuid, [this.campaign_influencer], 'producing')
            .then((response) => {
              this.$emit('update_header', response.results[0].campaign_header);
              this.show = false
              this.$emit('contentAdded', response)
              loadToastificationContent().then((component) => {
                this.$toast({
                  component: component.default,
                  props: {
                    title: this.$t('campaigns.contentAdd'),
                    icon: 'CheckCircleIcon',
                    text: this.$t('campaigns.contentSuccess'),
                    variant: 'success'
                  }
                })
              })
            })
        } else {
          this.show = false
          this.$emit('contentAdded', response)
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              props: {
                title: this.$t('campaigns.contentAdd'),
                icon: 'CheckCircleIcon',
                text: this.$t('campaigns.contentSuccess'),
                variant: 'success'
              }
            })
          })
        }
      })
    },
  }
};
</script>

<style>
.dropdownNet{
  border: 1px solid #D8D6DE;
  border-radius: 0.3em;
}
.p-auto .btn {
  padding: 0.6em !important;
}
.dropdownNet:focus-within{
  border: none;
  border: 1px solid #7367F0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;  
}
.text-center-modal{
  margin-left: 135px;
}
</style>
