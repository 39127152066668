<template>
  <div>
    <validation-observer #default="{ invalid }">
      <b-row class="w-100 mt-2">
        <b-col cols="4" class="mb-2 col-12" v-if="net !== 'facebook'">
          <b-row>
            <b-col class="col-12 col-lg-6 d-flex justify-content-center" v-if="content.content_type === 'story'">
              <b-avatar
                v-if="!isVideo(content.image_content)"
                :src="content.image_content ? content.image_content : ''"
                button
                @click="showVideo"
                class="img-story"
              />
              <b-avatar
                v-else
                :src="preview"
                class="img-story"
                button
                @click="showVideo"
              >
              </b-avatar>
            </b-col>
            <b-col class="col-12 col-lg-6" v-if="content.content_type === 'story'">
              <div class="justify-content-between flex-wrap mt-1">
                <div v-if="content.image_content">
                  <b-form-file
                    class="cursor-pointer-file"
                    placeholder="Cargar Story"
                    drop-placeholder="Drop file here..."
                    v-model="file"
                    @change="hasChanged"
                    accept=".jpeg, .jpg, .png, .mp4, .mov"
                    browse-text="Buscar"
                    ref="input-file"
                  />
                </div>
                <div v-else>
                  <validation-provider rules="required">
                    <b-form-file
                      class="cursor-pointer-file"
                      placeholder="Cargar Story"
                      drop-placeholder="Drop file here..."
                      v-model="file"
                      @change="hasChanged"
                      accept=".jpeg, .jpg, .png, .mp4, .mov"
                      browse-text="Buscar"
                      ref="input-file"
                    />
                  </validation-provider>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-col>
        <b-col class="col-12" v-if="content.content_type !== 'story'">
          <validation-provider rules="required">
            <b-form-group
                :label="`${$t('campaigns.link_of')} ${$t(`invitation.${content.content_type}`).toLowerCase()}`"
                :description="''"        
            >
                <b-input-group :class="`m-auto ${utils.urlStateExpression(content.url_info) ?  'is-valid' : 'is-invalid'}`">
                              
                  <b-input-group-prepend 
                    is-text 
                    :class="`${content.url_info ? 'eye-link' : ''}`"
                    >
                    <b-link
                      class="text-white a-padding-eye-icon"
                      :href="content.url_info"
                      target="_blank"
                      v-if="content.url_info"
                    >
                      <feather-icon icon="EyeIcon"/>
                    </b-link>
                    <feather-icon icon="LinkIcon" v-else/>
                  </b-input-group-prepend>
                  <b-form-input
                    :placeholder="`${$t('campaigns.link_placeholder_general')}${$t(`invitation.${content.content_type}`).toLowerCase()}`"
                    v-model="content.url_info"
                    type="url"
                    :state="utils.urlStateExpression(content.url_info)"
                  />
                  <b-input-group-append v-if="showMetricsButton()">
                    <b-button variant="success" @click="getMetrics">{{$t('campaigns.metrics')}}</b-button>
                  </b-input-group-append>
                </b-input-group>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col class="col-12" v-if="show_date_publish">
          <validation-provider rules="required">
            <b-form-group
              :label="$t('campaigns.date_publish')"
              :description="''"        
            > 
              <b-form-datepicker
                placeholder=""
                v-model="content.published_date"
                class="mb-2"
                :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
              ></b-form-datepicker>
            </b-form-group>
          </validation-provider>
        </b-col>
        <b-col :class="`col-12 ${new_layout ? 'col-lg-12' : 'col-lg-12'}`">
          <form-engagements :external_form="invalid" :content="content" :net="net" :new_layout="new_layout" @onSubmit="onSubmit" :key="change_content"></form-engagements>
        </b-col>
      </b-row>
    </validation-observer>

    <b-modal v-model="show_preview" centered hide-footer hide-header body-class="p-3 pt-5">
      <feather-icon
        icon="XIcon"
        size="24"
        class="icon-close"
        @click="closeModal()"
      />
      <div class="w-full d-flex justify-content-center">
        <video class="w-full" v-if="isVideoBlob(content.image_content)" controls>
          <source :src="content.image_content" type="video/mp4">
        </video>
        <b-embed
          v-else-if="isVideo(content.image_content)"
          type="iframe"
          aspect="4by3"
          :src="content.image_content"
          allowfullscreen
          class="img-story"
        ></b-embed>
        <b-img
          v-else
          :src="content.image_content ? content.image_content : ''"
          class="img-story"
        />
      </div>
      <div class="w-full d-flex justify-content-center mt-3">
        <b-button variant="success" @click="descargar(content)">Descargar</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import manage_invitation from "@/services/invitation";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import formEngagements from "@/views/components/campaign/formEngagements.vue";
import { getThumbnails } from 'video-metadata-thumbnails';
import utils from "../../../../../libs/utils";

import {
  BCol,
  BRow,
  BFormFile,
  BButton,
  BAvatar,
  BEmbed,
  BFormGroup,
  BFormDatepicker,
  BModal,
  BImg,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BInputGroupPrepend,
  BLink
} from "bootstrap-vue";

export default {
  name: 'GeneralForm',
  components: {
    ValidationProvider,
    ValidationObserver,
    BCol,
    BRow,
    BFormFile,
    BButton,
    BAvatar,
    BEmbed,
    BFormGroup,
    BFormDatepicker,
    formEngagements,
    BModal,
    BImg,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BInputGroupPrepend,
    BLink,
  },
  data() {
    return {
      utils,
      required,
      file: [],
      blob_preview: null,
      preview: '',
      show_preview: false,
      img_default: require("@/assets/images/dashboard/b1b1b1.png"),
      change_content: false,
      interval_loading: null
    };
  },
  props: {
    uuids: {
      required: true,
      type: Object,
    },
    content: {
      required: true,
      type: Object,
    },
    content_type: {
      type: String
    },
    net: {
      default: '',
      type: String,
    },
    info_add: {
      required: false,
      type: Object
    },
    snap: {
      required: false,
      type: Boolean
    },
    new_layout: {
      type: Boolean
    },
    show_date_publish: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    if (this.isVideo(this.content.image_content)) {
      if (!this.content.content_preview_url) {
        const thumbnails = getThumbnails(this.content.image_content, {
          quality: 0.6
        });

        thumbnails.then((value) => {
          this.preview = URL.createObjectURL(value[0].blob)
        });
      } else {
        this.preview = this.content.content_preview_url
      }
    }
  },
  methods: {
    descargar(content) {
      const a = document.createElement('a');
      a.href = content.image_content;
      a.download = "output.png";
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    },
    isVideoBlob(url) {
      if (url === null) return ''

      if (url.split(':')[0] === 'blob') {
        return this.file.type.split('/')[0] === 'video'
      } else {
        const split = url.split(".");
        const last_one = split[split.length - 1];
        return last_one.toLowerCase() === "mov";
      }
    },
    hasChanged() {
      setTimeout(() => {
        const url_blob = URL.createObjectURL(this.file)
        const thumbnails = getThumbnails(url_blob, {
          quality: 0.6
        });

        thumbnails.then((value) => {
          this.preview = URL.createObjectURL(value[0].blob)
          this.blob_preview = value[0].blob
        });
        this.content.image_content = URL.createObjectURL(this.file)
      },200)
    },
    showVideo() {
      if (this.content.image_content) this.show_preview = true
      else this.$refs['input-file'].$refs['input'].click()
    },
    closeModal() {
      this.show_preview = false
    },
    isVideo(url) {
      if (url === null) return ''
      if (url.split(':')[0] === 'blob') {
        return this.file.type.split('/')[0] === 'video'
      } else {
        const split = url.split(".");
        const last_one = split[split.length - 1];
        return last_one.toLowerCase() === "mp4" || last_one.toLowerCase() === "mov";
      }
    },
    onSubmit(new_metrics) {
      const form = new FormData();
      form.append("network", this.net);
      form.append("status", "content_published");
      if (this.file) {
        form.append("image_content", this.file);
      }
      form.append("content_type", this.content.content_type);
      

      if (this.blob_preview) {
        form.append("blob_preview", this.blob_preview);
      }
      form.append("url_info", this.content.url_info !== null ? this.content.url_info : "")

      this.content.number_of_impressions = new_metrics.impressions
      form.append("number_of_impressions", this.content.number_of_impressions);
      if (this.net !== 'blog' && this.net !== 'spotify') {
        this.content.number_of_likes = new_metrics.likes
        form.append("number_of_likes", this.content.number_of_likes);
      }
      
      if (this.net !== 'spotify') {
        this.content.number_of_comments = new_metrics.comments
        form.append("number_of_comments", this.content.number_of_comments);
      }
      this.content.number_of_shares = new_metrics.shares
      form.append("number_of_shares", this.content.number_of_shares);

      this.content.number_of_times_saved = new_metrics.saved
      if (new_metrics.saved) {
        form.append("number_of_times_saved", this.content.number_of_times_saved);
      }

      if (this.show_date_publish) {
        form.append('published_date', this.content.published_date);
      } else {
        form.append("published_date", (this.info_add.date_publish !== null) ? this.info_add.date_publish : "")
      }
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
      if (this.content.uuid === undefined) {

        form.append("campaign_influencer", this.uuids.campaign_influencer)
        form.append("network", this.net)
        form.append("estimated_cost", (this.info_add.estimated_cost !== null) ? this.info_add.estimated_cost : "")
        form.append("url_info", this.content.url_info !== null ? this.content.url_info : "")

        manage_invitation.addContentNoSession(this.uuids.campaign_uuid, form).then((response) => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
          if (!response.uuid) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('campaigns.error'),
                icon: 'BellIcon',
                text: response.data.response.message.url_info[0],
                variant: 'danger'
              }
            })
          } else {
            this.$emit('closeModal', response)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('campaigns.contentAdd'),
                icon: 'CheckCircleIcon',
                text: this.$t('campaigns.contentSuccess'),
                variant: 'success'
              }
            })  
          }
        })

      } else {
        manage_invitation
          .updateCampaignContent(this.uuids.campaign_uuid, this.content.uuid, form)
          .then((res) => {
            clearInterval(this.interval_loading)
            this.$vs.loading.close()
            res.index = this.content.index;
            this.$emit('closeModal', res);
            this.$emit('closeSidebar', res);
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('campaigns.infoUpdated'),
                icon: "CheckCircleIcon",
                variant: "success",
              },
            });
          })
          .catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t('campaigns.error'),
                icon: "BellIcon",
                text: this.$t('campaigns.infoFailed'),
                variant: "danger",
              },
            });
          });
      }
    },
    showMetricsButton() {
      if ((this.net === 'instagram' && (this.content_type === 'photo' || this.content_type === 'video')) ||
        (this.net === 'twitter' && (this.content_type === 'photo' || this.content_type === 'reel' || this.content_type === 'video')) ||
        (this.net === 'facebook' && (this.content_type === 'photo' || this.content_type === 'reel' || this.content_type === 'video' || this.content_type === 'post')) ||
        (this.net === 'pinterest' && this.content_type === 'photo') ||
        (this.net === 'linkedin' && this.content_type === 'photo'))
        return true
      
      return this.net === 'tiktok';
    },
    showLoader() {
      this.$vs.loading({
        type: 'sound',
        background: 'rgba(255, 255, 255, 1)',
        text: utils.getMotivationalPhrase(),
      });

      this.interval_loading = setInterval(() => {
        const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

        if (prev_loading) {
          prev_loading.classList.add('fade-out');
          setTimeout(function() {
            prev_loading.remove()
          }, 500);

          this.$vs.loading({
            type: 'sound',
            background: 'rgba(255, 255, 255, 1)',
            text: utils.getMotivationalPhrase(),
          });
        }
      }, 5000);
    },
    hideLoader() {
      clearInterval(this.interval_loading)
      this.$vs.loading.close()
    },
    getMetrics() {
      this.showLoader()
      const form = new FormData();
      form.append("network", this.net);
      form.append("link", this.content.url_info);

      manage_invitation.getMetrics(this.uuids.campaign_uuid, form).then((response) => {
        this.hideLoader()
        if (response.status >= 400) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('campaigns.MetricsNotObtained'),
              icon: "BellIcon",
              text: this.$t('campaigns.notRetrieved'),
              variant: "danger",
            }
          });
        } else {
          this.content.number_of_comments = response.comments
          this.content.number_of_likes = response.likes
          this.content.number_of_shares = response.shares
          this.content.number_of_views = response.views
          if (response.saves) this.content.number_of_times_saved = response.saves
          this.change_content = !this.change_content;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t('campaigns.capturedMetrics'),
              icon: 'BellIcon',
              text: this.$t('campaigns.metricsSuccess'),
              variant: 'success'
            },
          });
        }
      });
    }
  },
  watch: {
    content_type() {
      this.change_content = !this.change_content;
    }
  }
};
</script>

<style>
.form-file {
  width: 50%;
  margin-right: 1em;
}
.img-story {
  width: 170px;
  height: 170px;
  object-fit: cover;
  border-radius: 15px;
}
.disabled-success-button {
  background-color: #28c76f69 !important;
  border-color: #28c76f69 !important;
}
.disabled-success-button:active {
  background-color: #28c76f69 !important;
  border-color: #28c76f69 !important;
}
.icon-close {
  position: absolute;
  top: 10px;
  left: 10px;
  color: rgb(39, 31, 31);
  cursor: pointer;
}
.icon-close:hover {
  background-color: rgba(55, 57, 57, 0.5);
  border-radius: 50%;
  padding: 2px;
}
.cursor-pointer-file .custom-file-label {
  cursor: pointer;
}
</style>