<template>
  <div>
    <campaignHeader 
      :cover="header_data.cover" 
      :logo="header_data.logo" 
      :name="campaingName" 
      :data_left="array_left_header"
      :data_right="array_right_header"
      :badge="{traduction: ['oneCampaigninactive', 'oneCampaignactive'], value: header_data.is_archived}"
      @clickCalendar="clickCalendar"
      @updateBudget="updateBudget"
      @showInfluencer="showInfluencer"
      @showModalImport="modal_csv_import = true"
      :processing="processing"
      @update_logo_cover="updateLogoCover"
      :disabledUpdateCampaign="disabledUpdateCampaign()"
    />
    <b-tabs class="mt-4 campaign-tabs-principal" v-model="principal_tabs" active-nav-item-class="campaign-tabs-principal-active">
      <b-tab @click="isHidden = false; showAnalytics = false">
        <template #title>
          <feather-icon icon="UsersIcon" />
          <span>{{ $t('campaigns.profileProposals') }}</span>
        </template>
        <b-tabs v-model="secondary_tabs_proposals" content-class="mt-2">
            <b-tab active :title="$t('campaigns.allProfiles') + ' (' + header_data.proposals + ')'">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="all" @deleteInfluencer="deleteInfluencer" :key="`user_${key_all}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.pendingProfiles') +' (' + header_data.pending + ')'">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="pending" @deleteInfluencer="deleteInfluencer" :key="`user_${key_pending}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.approvedProfiles') + ' (' + header_data.approved + ')'">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="approved" @deleteInfluencer="deleteInfluencer" :key="`user_${key_approved}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.rejectedProfiles') + ' (' + header_data.rejected + ')'">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="rejected" @deleteInfluencer="deleteInfluencer" :key="`user_${key_rejected}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.onboarding') + ' (' + header_data.onboarding + ')'" v-if="header_data.onboarding">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="onboarding" @deleteInfluencer="deleteInfluencer" :key="`user_${key_onboarding}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.tradingProfiles') + ' (' + header_data.trading + ')'" v-if="header_data.trading">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="trading" @deleteInfluencer="deleteInfluencer" :key="`user_${key_trading}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.producing') + ' (' + header_data.production + ')'" v-if="header_data.production">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="producing" @deleteInfluencer="deleteInfluencer" :key="`user_${key_producing}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.submitted') + ' (' + header_data.submitted + ')'" v-if="header_data.submitted">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="submitted" @deleteInfluencer="deleteInfluencer" :key="`user_${key_submitted}`"/>
            </b-tab>

            <b-tab :title="$t('campaigns.published') + ' (' + header_data.published + ')'" v-if="header_data.published">
              <all-users @openSidebar="showInfluencer()" @childStatus="getFromChild" @bulkRemove="bulkRemove" @updateCounter="updateCounter" :campaign_name="campaingName" status_influencers="published" @deleteInfluencer="deleteInfluencer" :key="`user_${key_published}`"/>
            </b-tab>
        </b-tabs>
      </b-tab>
      
      <!-- Managuing -->
      <b-tab>
        <template #title>
          <feather-icon icon="UserCheckIcon" />
          <span>{{ $t('campaigns.squadCampaign') }}</span>
        </template>
        <b-tabs content-class="mt-2">
          <b-tab
          active
          :title="$t('campaigns.topCreator')"
          >

          <newTopCreator
            v-if="principal_tabs === 1"
            :slug="slug_name"
            @formatHeader="formatHeader"
            @update_header="updateHeader"
          />
          </b-tab>
        </b-tabs>
      </b-tab>

      <!-- Contents -->
      <b-tab @click="isHidden = true">
        <template #title>
          <feather-icon icon="CoffeeIcon" />
          <span>{{$t('campaigns.contents')}}</span>
        </template>
        <b-tabs content-class="mt-2" v-model="tabs_content">
          <b-tab
            active
            :title="$t('campaigns.contents_table')"
          >
            <PublishContent class="mt-2"
              :slug="slug_name"
              v-if="principal_tabs === 2 && tabs_content === 0"
              @update_header="updateHeader"
            />
          </b-tab>
          <b-tab
            :title="$t('campaigns.contents_see')"
          >          
            <div v-if="verifyEmptyStates() === 0" class="text-center mt-3"> 
              <img class="w-50" src="@/assets/images/elements/campaign/all.png"><br>
              <b-button variant="outline-primary" pill @click="showInfluencer()" :disabled="disabledUpdateCampaign()">{{ $t('campaigns.addInfluencer') }}</b-button>
            </div>
            <contents-view v-else-if="principal_tabs === 2 && tabs_content === 1"/>
          </b-tab>
        </b-tabs>
      </b-tab>

      <!-- Calendar -->
      <b-tab 
        empty
        @click="isHidden = true"  
      >
        <template #title>
          <feather-icon icon="CalendarIcon" />
          <span>{{$t('campaigns.calendar')}}</span>
        </template>
        <calendar-influencers :key="principal_tabs"/>
      </b-tab>

      <!-- Overview -->
      <b-tab>
        <template #title>
          <feather-icon icon="EditIcon" />
          <span>Overview</span>
        </template>
        <b-tabs content-class="mt-2">
          <b-tab
            :title="$t('campaigns.drag_drop')"
          >
            <dragDropStatus 
              :approvedManaging="usersManage" 
              v-if="principal_tabs === 4"
              @update_header="updateHeader"
            />
          </b-tab>
        </b-tabs>
      </b-tab>

      <!-- Analytics -->
      <b-tab :disabled="!showNewAnalytics">
        <template #title>
          <feather-icon icon="BarChart2Icon" />
          <span>{{ $t('campaigns.analytics') }}</span>
        </template>

        <analytics-campaign :campaingName="campaingName" v-if="showNewAnalytics && principal_tabs === 5"/>
      </b-tab>    

    </b-tabs>

    <add-influencer 
      @childPost="influencerAdded" 
      @update_header="updateHeader" 
      :show_side_influencer="sidebar_right"
      :key="has_changed_influencer"
    />

    <b-modal
      v-model="modal_csv_import"
      centered
      :title="$t('lists.addListTextCampaign')"
      hide-footer
      @close="closeFile"
    >
      
      <b-link href="https://brandme.la/wp-content/template_listas.csv"> 
        <feather-icon icon="DownloadIcon"/> 
        {{$t('campaigns.download_template')}}
      </b-link>

      <validation-observer ref="updateProfile" #default="{ invalid }">
        <validation-provider rules="required">
          <b-form-file
            accept=".csv"
            class="mt-1"
            v-model="fileCsv"
            :placeholder="$t('lists.addListText1')"
            :browse-text="$t('uploadFile')"
          ></b-form-file>
        </validation-provider>
        <b-button
          :disabled="invalid"
          variant="primary"
          class="my-1"
          @click="uploadFile"
        >
        {{$t('lists.upload')}}
        </b-button>
      </validation-observer>
    </b-modal>
  </div>
</template>

<script>
import {
  BTabs, BTab, BButton, VBToggle, BModal, BFormFile, BLink
} from 'bootstrap-vue'
import campaignHeader from './campaignHeader.vue'
import AllUsers from '@/views/components/campaign/alluser.vue'
import newTopCreator from '@/views/components/campaign/newTopCreator.vue'
import dragDropStatus from '@/views/components/campaign/dragDropStatus.vue'
import AddInfluencer from '@/views/campaings/influencerSearch.vue'
import PublishContent from '@/views/components/campaign/publishContent.vue'

import { ValidationProvider, ValidationObserver } from "vee-validate";

import service from "@/services/campaign";
import service_profile from '@/services/others'
import CalendarInfluencers from '@/views/campaings/calendar/CalendarInfluencers.vue'
import AnalyticsCampaign from './AnalyticsCampaign.vue'
import ContentsView from './contents/View.vue';
import { getMotivationalPhrase } from '@/libs/utils/others'
import { getUserData, hasPermission } from '@/libs/utils/user';
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');

export default {
  components: {
    newTopCreator,
    campaignHeader,
    BTabs,
    BTab,
    BButton,
    AllUsers,
    AddInfluencer,
    BModal,
    BFormFile,
    ValidationProvider,
    ValidationObserver,
    dragDropStatus,
    CalendarInfluencers,
    PublishContent,
    AnalyticsCampaign,
    ContentsView,
    BLink,
  },
  directives: {
    'b-toggle': VBToggle
  },
  data () {
    return {
      hasPermission,
      getUserData,
      getMotivationalPhrase,
      principal_tabs: 0,
      header_data: {},
      campaingName: '',
      slug_name: '',
      usersManage: {},
      infoRejected: [],
      isHidden: false,
      showAnalytics: false,
      fileCsv: null,
      processing: false,
      showNewAnalytics: false,
      tabs_content: 0,
      sidebar_right: false,
      array_right_header: [],
      array_left_header: [],
      modal_csv_import: false,
      secondary_tabs_proposals: 0,
      has_changed_influencer: false,
      key_approved: 0,
      key_rejected: 0,
      key_all: 0,
      key_pending: 0,
      key_onboarding: 0,
      key_trading: 0,
      key_producing: 0,
      key_submitted: 0,
      key_published: 0,
      interval_loading: null
    }
  },
  created () {
    this.$vs.loading({
      type: 'sound',
      background: 'rgba(255, 255, 255, 1)',
      text: getMotivationalPhrase(),
    });

    this.interval_loading = setInterval(() => {
      const prev_loading = document.getElementsByClassName('con-vs-loading')[0]

      if (prev_loading) {
        prev_loading.classList.add('fade-out');
        setTimeout(function() {
          prev_loading.remove()
        }, 500);

        this.$vs.loading({
          type: 'sound',
          background: 'rgba(255, 255, 255, 1)',
          text: getMotivationalPhrase(),
        });
      }
    }, 5000);
    service.getCampaign(this.$route.params.uuid)
      .then(response => {
        this.slug_name = response.slug_name
        this.campaingName = response.name
        this.header_data = {
          logo: response.logo,
          cover: response.cover,
          is_archived: response.is_archived,
          budget: response.budget,
          production: response.stats_fields.production,
          proposals: response.stats_fields.proposals,
          published: response.stats_fields.published,
          spent: response.stats_fields.spent ? response.stats_fields.spent : 0,
          onboarding: response.stats_fields.onboarding,
          trading: response.stats_fields.trading,
          pending: response.stats_fields.pending,
          approved: response.stats_fields.approved,
          rejected: response.stats_fields.rejected,
          submitted: response.stats_fields.submitted,
        }
        this.fillHeaderData()
        setTimeout(() => {
          clearInterval(this.interval_loading)
          this.$vs.loading.close()
        }, 500);
      })
    this.getCampaignData(this.$route.params.uuid)
    this.checkProcess()
    const self = this
    setTimeout(() => {
      self.checkHashParams()
    }, 500);
  },
  methods: {
    updateLogoCover(data) {
      this.header_data.logo = data.logo;
      this.header_data.cover = data.cover;
    },
    checkOverviewUrl() {
      const list_urls = [];
      if (this.header_data.onboarding) list_urls.push('#propuestas-onboarding');
      if (this.header_data.trading) list_urls.push('#propuestas-negociando');
      if (this.header_data.production) list_urls.push('#propuestas-produciendo');
      if (this.header_data.submitted) list_urls.push('#propuestas-enviadas');
      if (this.header_data.published) list_urls.push('#propuestas-publicadas');
      return list_urls;
    },
    checkHashParams() {
      const param = location.hash.replace('#', '')
      switch (param) {
      case 'propuestas':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 0
        break
      case 'propuestas-pendientes':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 1
        break
      case 'propuestas-aprobadas':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 2
        break
      case 'propuestas-rechazadas':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 3
        break
      case 'propuestas-onboarding':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 4
        break
      case 'propuestas-negociando':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 5
        break
      case 'propuestas-produciendo':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 6
        break
      case 'propuestas-enviadas':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 7
        break
      case 'propuestas-publicadas':
        this.principal_tabs = 0
        this.secondary_tabs_proposals = 8
        break
      case 'squad':
        this.principal_tabs = 1
        break
      case 'contenidos':
        this.principal_tabs = 2
        this.tabs_content = 0
        break
      case 'contenidos-vista':
        this.principal_tabs = 2
        this.tabs_content = 1
        break
      case 'calendario':
        this.principal_tabs = 3
        break
      case 'overview':
        this.principal_tabs = 4
        break
      case 'analytics':
        if (this.verifyEmptyStates() > 0) {
          this.principal_tabs = 5
        }
        break
      }
    },
    updateBudget(data) {
      this.array_right_header[2].value = `${data}`
    },
    showInfluencer() {
      this.sidebar_right = true;
      this.has_changed_influencer = !this.has_changed_influencer;
    },
    formatHeader(data) {
      this.header_data.production = data.production
      this.header_data.proposals = data.proposals
      this.header_data.published = data.published
      this.header_data.spent = data.spent ? data.spent : 0
      this.header_data.onboarding = data.onboarding
      this.header_data.trading = data.trading
      this.header_data.pending = data.pending
      this.header_data.approved = data.approved
      this.header_data.rejected = data.rejected
      this.header_data.submitted = data.submitted
      this.showNewAnalytics = data.published > 0
    },
    verifyEmptyStates() {
      return this.header_data.approved + this.header_data.onboarding + this.header_data.trading + this.header_data.production + this.header_data.submitted + this.header_data.published;
    },
    updateHeader() {
      this.getCampaignData(this.$route.params.uuid)
    },
    clickCalendar() {
      this.principal_tabs = 3;
    },
    fillHeaderData() {
      const array_left = [
        {traduction : 'proposalscard', value: this.header_data.proposals, key: 'proposals'},
        {traduction : 'productioncard', value: this.header_data.production, key: 'production'},
        {traduction : 'publishedcard', value: this.header_data.published, key: 'published'},
      ]
      const array_right = [
        {traduction : 'campaigns.onboarding', value: `${this.header_data.onboarding}`, key: 'onboarding'},
        {traduction : 'campaigns.tradingProfiles', value: `${this.header_data.trading}`, key: 'trading'},
        {traduction : 'campaigns.budget', value: `${this.header_data.budget}`, key: 'budget'},
        {traduction : 'spentcard', value:  `${this.header_data.spent}`, key: 'spent'},
      ]
      this.array_right_header = array_right;
      this.array_left_header = array_left;
    },
    checkProcess() {
      service.checkProcess()
        .then(response => {
          this.processing = response.processing
        })
    },
    closeFile() {
      this.fileCsv = null;
    },
    uploadFile() {
      const formData = new FormData();
      formData.set("file_csv", this.fileCsv);
      this.processing = true;
      service.uploadCsv(this.$route.params.uuid, formData).then((res) => {
        if (res.results) {
          this.fileCsv = null;
          this.modal_csv_import = false;
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              props: {
                title: this.$t('lists.addSuccess'),
                icon: "CheckCircleIcon",
                text: this.$t('lists.addSuccessText'),
                variant: "success",
              }
            },
            {
              timeout: 20000
            })
          })
          service_profile.getMaxViews()
            .then(response => {
              this.$root.$emit('change_max_views', response.max_views)
            })
        } else if (res.code && res.code === 'csv.BadFormat') {
          this.$swal({
            title: this.$t('lists.errorCsv'),
            text:  this.$t('lists.formatCsv'),
            imageUrl: require('@/assets/images/lists/example_csv.png'),
            imageWidth: 400,
            imageHeight: 200,
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            heightAuto: false,
          })
        } else if (res.code && res.code === 'campaign.Processing') {
          this.$swal({
            title: this.$t('lists.titleCsvProcess'),
            text: this.$t('lists.textCvsProcess'),
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            heightAuto: false,
          })
        } else if (res.code && res.code === 'accounts.max_views_soldout') {
          this.$swal({
            title: this.$t('error.soldoutViews'),
            icon: 'danger',
            customClass: {
              confirmButton: 'btn btn-danger'
            },
            buttonsStyling: false,
            heightAuto: false,
          })
        }
      });
    },
    clickAnalytics() {
      this.isHidden = false;
      this.showAnalytics = true;
    },
    isSuperUser() {
      return getUserData().is_superuser
    },
    influencerAdded() {
      this.key_all += 1;
      this.key_pending += 1;
      this.getCampaignData(this.$route.params.uuid)
    },
    bulkRemove(tab_deleted) {
      if (tab_deleted === 'all') {
        this.key_pending += 1;
        this.key_approved += 1;
        this.key_rejected += 1;
        this.key_onboarding += 1;
        this.key_trading += 1;
        this.key_producing += 1;
        this.key_submitted += 1;
        this.key_published += 1;
      } else {
        this.key_all += 1;
      }

      this.getCampaignData(this.$route.params.uuid)
    },
    deleteInfluencer(tab_deleted, status) {
      if (tab_deleted !== 'all') {
        this.key_all += 1;
      } else if (status === 'pending') {
        this.key_pending += 1;
      } else if (status === 'approved') {
        this.key_approved += 1;
      } else if (status === 'rejected') {
        this.key_rejected += 1;
      } else if (status === 'onboarding') {
        this.key_onboarding += 1;
      } else if (status === 'trading') {
        this.key_trading += 1;
      } else if (status === 'producing') {
        this.key_producing += 1;
      } else if (status === 'submitted') {
        this.key_submitted += 1;
      } else if (status === 'published') {
        this.key_published += 1;
      }
      this.getCampaignData(this.$route.params.uuid)
    },
    getFromChild () {
      this.key_approved += 1
      this.key_rejected += 1
      service.getCampaignInfluencers(this.$route.params.uuid)
        .then(response => {
          if (response.results && response.results.length > 0) {
            const influencer = response.results[0]

            this.formatHeader(influencer.campaign_header)
          } else {
            this.header_data.production = 0
            this.header_data.proposals = 0
            this.header_data.published = 0
            this.header_data.onboarding = 0
            this.header_data.trading = 0
            this.header_data.pending = 0
            this.header_data.approved = 0
            this.header_data.rejected = 0
            this.header_data.submitted = 0
          }
        })
      this.sidebar_right = false
    },
    updateCounter() {
      this.getCampaignData(this.$route.params.uuid);
    },
    getCampaignData(campaign_uuid) {
      service.getCampaignInfluencers(campaign_uuid)
        .then(response => {
          if (response.results && response.results.length > 0) {
            const influencer = response.results[0]

            this.formatHeader(influencer.campaign_header)
          } else {
            this.header_data.production = 0
            this.header_data.proposals = 0
            this.header_data.published = 0
            this.header_data.onboarding = 0
            this.header_data.trading = 0
            this.header_data.pending = 0
            this.header_data.approved = 0
            this.header_data.rejected = 0
            this.header_data.submitted = 0
          }
        })
    },
    disabledUpdateCampaign() {
      return !hasPermission('change_campaign')
    },
  },
  mounted () {
  },
  watch: {
    principal_tabs() {
      const sections = [
        '#propuestas',
        '#squad',
        '#contenidos',
        '#calendario',
        '#overview',
        '#analytics'
      ]

      history.pushState({urlPath:""},"",sections[this.principal_tabs]);
    },
    secondary_tabs_proposals() {
      let sections = [
        '#propuestas',
        '#propuestas-pendientes',
        '#propuestas-aprobadas',
        '#propuestas-rechazadas',
      ];
      sections = sections.concat(this.checkOverviewUrl())
      const interval = setInterval(() => {
        if (this.principal_tabs === 0) {
          history.pushState({urlPath:""},"",sections[this.secondary_tabs_proposals]);
          clearInterval(interval)
        }
      },500)  
    },
    tabs_content() {
      const sections = [
        '#contenidos',
        '#contenidos-vista'
      ]
      const interval_content = setInterval(() => {
        if (this.principal_tabs === 2) {
          history.pushState({urlPath:""},"",sections[this.tabs_content]);
          clearInterval(interval_content)
        }
      },500)
    }
  }
}
</script>

<style>
.b-sidebar{
  width: 500px !important;
}
.buttonAddInfluencer{
  background-color: #0096fe !important;
  color: white !important;
  margin-right: 5px;
}
.buttonAddInfluencer:hover {
  background-color:#4db6f9 !important;
  margin-right: 5px;
}
.button-csv{
  background-color: transparent !important;
  color:grey !important;
  border: grey solid 1px !important;
  margin-left: 5px !important;
}
.button-csv:focus{
  background-color: transparent !important;
  color:grey !important;
  border: grey solid 1px !important;
  margin-left: 5px !important;
}
.button-brief{
  margin-right: 10px;
}
.campaign-tabs-principal .nav-link{
  color: #868fb6 ;
}
.campaign-tabs-principal .nav-link:hover {
  color: #bfbfc8;
}
.campaign-tabs-principal-active {
  color: #4826ef !important;
  border-bottom: 2px solid #4826ef !important;
}
.nav-tabs .nav-link:after {
  background: none !important;
}

</style>
