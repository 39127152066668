import config from '@/services/config'
import axios from './axios_interceptors'
import {tokenExpired} from '@/auth/utils'

export default {
  getChildInfo(campaign_uuid, child_uuid) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/external/${child_uuid}`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getStories(uuid, username) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${uuid}/stories/${username}`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  getSnaps(uuid, username) {
    return axios.get(`${config.urlCore}/api/v1/campaign/${uuid}/snaps/${username}`, {
      headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
    }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  deleteContent(campaign_uuid, form_data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/remove/`,
      form_data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  deleteBulkContent(campaign_uuid, content_uuids) {
    const data = {content_uuids}
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/bulk/remove/`,
      data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  updateInfluencer(campaign_uuid, influencer_uuid, form_data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/updated/${influencer_uuid}`,
      form_data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  updateContent(campaign_uuid, content_uuid, form_data) {
    return axios.post(`${config.urlCore}/api/v1/campaign/${campaign_uuid}/manage/update/${content_uuid}`,
      form_data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  },
  updateSentiment(campaign_uuid, form_data) {
    return axios.post(`${config.urlCore}/api/v3/campaign/${campaign_uuid}/update/sentiment/`,
      form_data,
      {
        headers: {Authorization: `Bearer ${localStorage.getItem('accessToken')}`}
      }).then(response => response.data.response)
      .catch(error => {
        if (error.response.status === 401) tokenExpired
        return error.response
      })
  }
}
