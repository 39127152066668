<template>
  <div class="sidebar-wrapper d-flex justify-content-between flex-column flex-grow-1">
    <div class="pl-2 pr-2">
      <div class="mt-3">
        <b-card no-body class="mb-1 no-shadow">
          <b-card-header header-tag="header" class="p-0 cursor-pinter" role="tab" block v-b-toggle.accordion-filter-type>
            <b>
              {{$t('campaigns.type_influencer')}}:
              <feather-icon
                v-if="accordion_type"
                icon="MinusIcon"
                size="16"
              />
              <feather-icon
                v-else
                icon="PlusIcon"
                size="16"
              />
            </b>
          </b-card-header>
          <b-collapse v-model="accordion_type" id="accordion-filter-type" visible accordion="accordion-filters" role="tabpanel">
              <b-form-group v-if="accordion_type">
                <template #label>
                  <b-form-checkbox
                    v-model="allSelected"
                    aria-describedby="selected_type"
                    aria-controls="selected_type"
                    @change="toggleAll"
                    class="custom-control-dark mt-2"
                  >
                    {{$t('influencerCategory.viewAll')}}
                  </b-form-checkbox>
                </template>

                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="selected_type"
                    v-model="selected"
                    :aria-describedby="ariaDescribedby"
                    name="selected_type"
                    aria-label="Individual types"
                    stacked
                  >
                    <b-form-checkbox
                      v-for="item in type_influencers"
                      :key="item.type"
                      name="event-filter"
                      :value="item.type"
                      class="mb-1"
                      :class="`custom-control-${item.variant}`"
                    >
                      {{ $t(`influencerCategory.${item.type}`) }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </b-form-group>
          </b-collapse>
        </b-card>

        <b-card no-body class="mb-1 no-shadow">
          <b-card-header header-tag="header" class="p-0 cursor-pinter" role="tab" block v-b-toggle.accordion-filter-name>
            <b>
              {{$t('campaigns.nameInfluencers')}}:
              <feather-icon
                v-if="accordion_name"
                icon="MinusIcon"
                size="16"
              />
              <feather-icon
                v-else
                icon="PlusIcon"
                size="16"
              />
            </b>
          </b-card-header>
          <b-collapse v-model="accordion_name" id="accordion-filter-name" visible accordion="accordion-filters" role="tabpanel">
              <b-form-group v-if="accordion_name">
                <template #label>
                  <b-form-checkbox
                    v-model="allSelectedInfluencers"
                    aria-describedby="selected_type_influencers"
                    aria-controls="selected_type_influencers"
                    @change="toggleAllInfluencers"
                    class="custom-control-dark mt-2"
                  >
                    {{$t('influencerCategory.viewAll')}}
                  </b-form-checkbox>
                </template>

                <template v-slot="{ ariaDescribedby }">
                  <b-form-checkbox-group
                    id="selected_type_influencers"
                    v-model="name_influencers_selected"
                    :aria-describedby="ariaDescribedby"
                    name="selected_type_influencers"
                    aria-label="Individual types"
                    stacked
                  >
                    <b-form-checkbox
                      v-for="item, index in name_influencers"
                      :key="index"
                      name="event-filter"
                      :value="item.name"
                      class="mb-1"
                      :class="`custom-control-${item.variant}`"
                    >
                      {{ item.name }}
                    </b-form-checkbox>
                  </b-form-checkbox-group>
                </template>
              </b-form-group>
          </b-collapse>
        </b-card>
      </div>
    </div>
    <b-img :src="require('@/assets/images/pages/calendar-illustration.png')" />
  </div>
</template>

<script>
import {BFormGroup, BFormCheckboxGroup, BFormCheckbox, BCard, BCardHeader, BCollapse, VBToggle, BImg} from 'bootstrap-vue'

export default {
  name: 'CalendarSidebarInfluencers',
  directives: {
    'b-toggle': VBToggle,

  },
  components: {
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox,
    BCard,
    BCardHeader,
    BCollapse,
    BImg
  },
  props: {
    influencers: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      accordion_type: true,
      accordion_name: false,
      allSelected: true,
      allSelectedInfluencers: true,
      selected_type: [
        'top-celebrity',
        'top-influencer-celebrity',
        'macroinfluencer',
        'microinfluencer',
        'nanoinfluencer',
      ],
      selected: [
        'top-celebrity',
        'top-influencer-celebrity',
        'macroinfluencer',
        'microinfluencer',
        'nanoinfluencer',
        'top-celebrity',
      ],
      type_influencers: [
        {type: 'top-celebrity', variant: 'info'},
        {type: 'top-influencer-celebrity', variant: 'danger'},
        {type: 'macroinfluencer', variant: 'primary'},
        {type: 'microinfluencer', variant: 'warning'},
        {type: 'nanoinfluencer', variant: 'success'},
      ],
      selected_type_influencers: [],
      name_influencers_selected: [],
      name_influencers: [],
    }
  },
  created() {
    this.influencers.forEach(influencer => {
      const variant = this.type_influencers.find(item => item.type === influencer.influencer.calculate_category)
      this.name_influencers.push({name: influencer.influencer.name, variant: variant ? variant.variant : 'secondary'})
      this.selected_type_influencers.push(influencer.influencer.name)
    })
  },
  methods: {
    toggleAllInfluencers(checked) {
      this.name_influencers_selected = checked ? this.selected_type_influencers.slice() : []
    },
    toggleAll(checked) {
      this.selected = checked ? this.selected_type.slice() : []
    }
  },
  watch: {
    selected(newValue) {
      if (newValue.length === 0) {
        this.allSelected = false
      } else if (newValue.length === this.selected_type.length) {
        this.allSelected = true
      } else {
        this.allSelected = false
      }
      
      const filters = this.selected.concat(this.name_influencers_selected)

      this.$emit('filterEvents', filters)
    },
    name_influencers_selected(newValue) {
      if (newValue.length === 0) {
        this.allSelectedInfluencers = false
      } else if (newValue.length === this.selected_type_influencers.length) {
        this.allSelectedInfluencers = true
      } else {
        this.allSelectedInfluencers = false
      }
      
      const filters = this.selected.concat(this.name_influencers_selected)

      this.$emit('filterEvents', filters)
    },
    accordion_type(new_value) {
      if (!new_value && !this.accordion_name) {
        this.selected = []
        this.name_influencers_selected = this.selected_type_influencers
        this.accordion_name = true
      } if (!new_value && this.accordion_name) {
        this.selected = []
        this.name_influencers_selected = this.selected_type_influencers
      } else {
        this.selected = this.selected_type
        this.name_influencers_selected = []
      }
    },
    accordion_name(new_value) {
      if (!new_value && !this.accordion_type) {
        this.accordion_type = true
      }
    }
  }
}
</script>

<style lang="scss">
.no-shadow {
  box-shadow: none;
}
.cursor-pinter {
  cursor: pointer;
}
</style>
