<template>
  <div>
    <validation-observer #default="{ invalid }">
      <b-row class="w-100 mt-2">
        <b-col cols="12">
          <b-row>
            <b-col class="col-12">
                <validation-provider rules="required" class="m-auto">
                  <b-form-group
                    :label="$t('campaigns.post_link_label')"
                    :description="''"
                  >
                    <b-input-group>
                      <b-input-group-prepend is-text :class="`${content.url_info ? 'eye-link' : ''}`">
                        <b-link
                          class="text-white a-padding-eye-icon"
                          :href="content.url_info"
                          target="_blank"
                          v-if="content.url_info"
                        ><feather-icon icon="EyeIcon"/></b-link>
                        <feather-icon icon="LinkIcon" v-else/>
                      </b-input-group-prepend>
                      <b-form-input
                        :placeholder="$t('campaigns.post_link_placeholder')"
                        v-model="content.url_info"
                        type="url"
                      />
                      <b-input-group-append v-if="net === 'facebook'">
                        <b-button variant="success" @click="getMetrics">{{$t('campaigns.metrics')}}</b-button>
                      </b-input-group-append>
                    </b-input-group>
                  </b-form-group>
                </validation-provider>
            </b-col>

            <b-col class="col-12" v-if="show_date_publish">
              <validation-provider rules="required">
                <b-form-group
                  :label="$t('campaigns.date_publish')"
                  :description="''"        
                >
                  <b-form-datepicker
                    placeholder=""
                    v-model="content.published_date"
                    class="mb-2"
                    :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit'}"
                  ></b-form-datepicker>
                </b-form-group>
              </validation-provider>
            </b-col>
            
            <b-col class="col-12">
              <form-engagements :external_form="invalid" :content="content" :net="net" :new_layout="new_layout" @onSubmit="onSubmit"></form-engagements>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import manage_invitation from "@/services/invitation";
const loadToastificationContent = () => import('@core/components/toastification/ToastificationContent.vue');
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { required } from "@validations";
import formEngagements from "@/views/components/campaign/formEngagements.vue";

import {
  BInputGroup,
  BInputGroupPrepend,
  BFormInput,
  BCol,
  BRow,
  BButton,
  BInputGroupAppend,
  BFormGroup,
  BLink,
  BFormDatepicker
} from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BCol,
    BRow,
    BButton,
    BInputGroupAppend,
    BFormGroup,
    BLink,
    BFormDatepicker,
    formEngagements
  },
  data() {
    return {
      required,
    };
  },
  props: {
    uuids: {
      required: true,
      type: Object,
    },
    content: {
      required: true,
      type: Object,
    },
    net: {
      required: true,
      type: String,
    },
    info_add: {
      required: false,
      type: Object
    },
    new_layout: {
      type: Boolean
    },
    show_date_publish: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  methods: {
    onSubmit(new_metrics) {
      this.content.number_of_impressions = new_metrics.impressions
      this.content.number_of_likes = new_metrics.likes
      this.content.number_of_comments = new_metrics.comments
      this.content.number_of_shares = new_metrics.shares
      this.content.number_of_times_saved = new_metrics.saved

      const form = new FormData();
      form.append("network", this.net);
      form.append("status", "content_published");
      form.append("content_type", this.content.content_type);
      form.append('campaign_influencer', undefined);

      form.append('url_info', this.content.url_info);
      form.append("number_of_impressions", this.content.number_of_impressions);

      if (new_metrics.likes) {
        form.append("number_of_likes", this.content.number_of_likes);
      }

      if (new_metrics.comments) {
        form.append("number_of_comments", this.content.number_of_comments);
      }

      if (new_metrics.saved) {
        form.append("number_of_times_saved", this.content.number_of_times_saved);
      }
      
      form.append("number_of_shares", this.content.number_of_shares);


      if (this.show_date_publish) {
        form.append('published_date', this.content.published_date);
      } else {
        form.append("published_date", (this.info_add.date_publish !== null) ? this.info_add.date_publish : "")
      }
    
      if (this.content.uuid === undefined) {

        form.append("campaign_influencer", this.uuids.campaign_influencer)
        form.append("network", this.net)
        form.append("estimated_cost", (this.info_add.estimated_cost !== null) ? this.info_add.estimated_cost : "")


        manage_invitation.addContent(this.uuids.campaign_uuid, form).then((response) => {
          if (!response.uuid) {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.error'),
                  icon: 'BellIcon',
                  text: response.data.response.message.url_info[0],
                  variant: 'danger'
                }
              })
            })
          } else {
            this.$emit('closeModal', response)
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.contentAdd'),
                  icon: 'CheckCircleIcon',
                  text: this.$t('campaigns.contentSuccess'),
                  variant: 'success'
                }
              })
            })
          }
        })

      } else {
        manage_invitation
          .updateCampaignContent(this.uuids.campaign_uuid, this.content.uuid, form)
          .then((res) => {
            res.index = this.content.index;
            this.$emit('closeModal', res)
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.infoUpdated'),
                  icon: "CheckCircleIcon",
                  variant: "success",
                }
              })
            })
          })
          .catch(() => {
            loadToastificationContent().then((component) => {
              this.$toast({
                component: component.default,
                props: {
                  title: this.$t('campaigns.error'),
                  icon: "BellIcon",
                  text: this.$t('campaigns.infoFailed'),
                  variant: "danger",
                }
              })
            })
          });
      }
    },
    getMetrics() {
      const form = new FormData();
      form.append("network", this.net);
      form.append("link", this.content.url_info);

      manage_invitation.getMetrics(this.uuids.campaign_uuid, form).then((response) => {
        if (response.status >= 400) {
          this.content.number_of_comments = response.comments
          this.content.number_of_likes = response.likes
          this.content.number_of_shares = response.shares
          this.content.number_of_views = response.views
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              props: {
                title: this.$t('campaigns.capturedMetrics'),
                icon: 'BellIcon',
                text: this.$t('campaigns.metricsSuccess'),
                variant: 'success'
              }
            })
          })
        } else {
          loadToastificationContent().then((component) => {
            this.$toast({
              component: component.default,
              props: {
                title: this.$t('campaigns.MetricsNotObtained'),
                icon: "BellIcon",
                text: this.$t('campaigns.notRetrieved'),
                variant: "danger",
              }
            })
          })
        }
      });
    }
  },
};
</script>